import React, { useState } from 'react';
import styled from "styled-components";
import Text from "./Text";
import { TriangleArrow } from "../icons/TriangleArrow";
import Spacer from "./Spacer";

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
`

const Line1 = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;

  :hover {
    color: ${({ theme }) => theme.colors.background};
    background-color: ${({ theme }) => theme.colors.accent};
  }
`

const Line = styled.a`
  display: ${p => p.open ? "flex" : "none"};
  align-items: center;
  position: absolute;
  top: ${({ $index }) => $index * 100}%;
  left: 0;
  width: 100%;
  padding: 5px;

  :hover {
    color: ${({ theme }) => theme.colors.background};
    background-color: ${({ theme }) => theme.colors.accent};
  }
`

const Line2 = styled.a`
  display: ${p => p.open ? "flex" : "none"};
  align-items: center;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 5px;

  :hover {
    color: ${({ theme }) => theme.colors.background};
    background-color: ${({ theme }) => theme.colors.accent};
  }
`

const Line3 = styled.a`
  display: ${p => p.open ? "flex" : "none"};
  align-items: center;
  position: absolute;
  top: 200%;
  left: 0;
  width: 100%;
  padding: 5px;

  :hover {
    color: ${({ theme }) => theme.colors.background};
    background-color: ${({ theme }) => theme.colors.accent};
  }
`

const Arrow = styled(TriangleArrow)`
  width: 15px;
  height: 15px;
`

const SelectYear = (props) => {
  const { data } = props;
  const [open, setOpen] = useState(false)

  return (
    <Comp onMouseLeave={() => setOpen(false)}>
      <Line1 onClick={() => setOpen(!open)}>
        <Text s="cardsField">
          2022
        </Text>
        <Spacer s="smaller" />

        <Arrow />
      </Line1>

      <Line $index={1} open={open} href="https://2024.head-geneve.show">
        <Text s="cardsField">
          2024
        </Text>
      </Line>

      <Line $index={2} open={open} href="https://2023.head-geneve.show">
        <Text s="cardsField">
          2023
        </Text>
      </Line>

      <Line $index={3} open={open} href="https://2021.head-geneve.show">
        <Text s="cardsField">
          2021
        </Text>
      </Line>
    </Comp>
  );
};

export default SelectYear;
