import React from 'react';
import styled from "styled-components";
// import ContactStudent from "./ContactStudent";
import Spacer from "../layout/Spacer";
import Instagram from "./Instagram";
import Cursus from "./Cursus";
import DataList from "./DataList";
import ProjectLinks from "./ProjectLinks";
import Thesis from "./Thesis";
import ContactStudent from "./ContactStudent";
// import Conditional from "../layout/Conditional";
// import ProjectContent from "./ProjectContent";

const Comp = styled.div`
  //width: 100%;
  //max-width: 50%;
  flex: 0.3;
  margin-right: ${({theme}) => theme.spacer.large[0]};

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    // prev m
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
`

// const ProjectText = styled(Text)`
//   white-space: pre-wrap;
// `

const ProjectData = (props) => {
        const {data, pageData} = props

        const insta = pageData.find(i => i.identification.id === "instagram preposition").content
        const contact = pageData.find(i => i.identification.id === "contact").content
        const projectUrl = pageData.find(i => i.identification.id === "projecturl").content
        const projectSocialMedia = pageData.find(i => i.identification.id === "projectsocialmedia").content
        const tutors = pageData.find(i => i.identification.id === "tutors").content
        const awards = pageData.find(i => i.identification.id === "awards").content
        const thesis = pageData.find(i => i.identification.id === "thesislink").content
        // const media = pageData.find(i => i.identification.id === "media").content
        // const tags = pageData.find(i => i.identification.id === "tags").content

        const contactData = pageData.filter(i => i.identification.section === "contact")
        // console.log(data.thesisLink)

        return (
            <Comp>

                {data.authors[0].contactAllowed ?
                    <>
                        <ContactStudent
                            name={data.authors[0].firstName}
                            address={data.authors[0].email}
                            text={contact}
                            contactData={contactData}
                        />
                        <Spacer s="medium"/>
                    </>
                    : null}

                {/*<Text s="small">*/}
                {/*    2021*/}
                {/*</Text>*/}
                {/*<Spacer s="medium"/>*/}

                {data.authors[0].socialMedia[0] ?
                    <>
                        <Instagram data={data} text={insta}/>
                        <Spacer s="medium"/>
                    </>
                    : null}

                {data.thesisLink ?
                    <>
                        <Thesis data={data} text={thesis}/>
                        <Spacer s="medium"/>
                    </>
                    : null}

                <ProjectLinks
                    url={data.projectUrl}
                    urlText={projectUrl}
                    socialMedia={data.socialMedia}
                    socialMediaText={projectSocialMedia}
                />

                {data.awards.length > 0 ?
                    <>
                        <DataList number text={awards} data={data.awards} link="website"/>
                        <Spacer s="medium"/>
                    </>
                    : null}

                <Cursus data={data} pageData={pageData}/>

                {/*<Conditional mobile>*/}
                {/*    <ProjectContent*/}
                {/*        text={props.text}*/}
                {/*        images={props.images}*/}
                {/*        videos={props.videos}*/}
                {/*    />*/}
                {/*</Conditional>*/}

                <DataList text={tutors} data={data.tutors} prefix="/catalogue#tutors=" link="_id"/>

                {/*<DataList number text={media} data={data.media} prefix="/catalogue#media=" link="_id"/>*/}
                {/*<Spacer s="medium"/>*/}

                {/*<DataList number text={tags} data={data.tags} prefix="/catalogue#tags=" link="_id"/>*/}
            </Comp>
        );
    }
;

export default ProjectData;
