import * as React from "react"
import Layout from "../components/layout/Layout";
import Spacer from "../components/layout/Spacer";
import Edito from "../components/01_home/Edito";
import Diplomas from "../components/01_home/Diplomas";
import Events from "../components/01_home/Events";
import {graphql} from "gatsby";
import localize from "../components/localization/localize";
import Splash from "../components/01_home/22_splash/Splash";
import {SplashProvider} from "../contexts/SplashContext";
import Conditional from "../components/layout/Conditional";
import SplashMobile from "../components/01_home/22_splash/SplashMobile";
import AwardsList from "../components/01_home/AwardsList";

// markup
const IndexPage = ({data, ...props}) => {
    const content = data.sanityPage.content

    const introData = content.filter(item => item.identification.section === "header")
    const editoData = content.filter(item => item.identification.section === "editorial")
    const bachelorData = content.filter(item => item.identification.section === "bachelor")
    const masterData = content.filter(item => item.identification.section === "master")
    const eventsData = content.filter(item => item.identification.section === "events")
    // const visitData = content.filter(item => item.identification.section === "visit")
    // const visitLinks = content.filter(item => item.identification.section === "visitlink")

    const bachelorContent = content.filter(item => item.identification.section === "bachelordiploma")
    const masterContent = content.filter(item => item.identification.section === "masterdiploma")

    const events = data.allSanityEvent.nodes
    const projects = data.allSanityProject.nodes

    return (
        <>
            <Layout lang={props.pageContext.locale} title={data.sanityPage.title} siteTitle={data.sanityPage.title}
                    footerVideo>
                <SplashProvider>
                    <Conditional breakPoint="ms" desktop>
                        <Splash projects={projects} introData={introData}/>
                    </Conditional>
                    <Conditional breakPoint="ms" mobile>
                        <SplashMobile projects={projects} introData={introData}/>
                    </Conditional>
                </SplashProvider>
                {/*hello*/}

                <Spacer s="small"/>

                {/*<Introduction*/}
                {/*    data={introData}*/}
                {/*    projects={projects}*/}
                {/*/>*/}
                <Spacer s="larger"/>

                <Edito data={editoData}/>
                <Spacer s="large"/>

                <Diplomas
                    bachelor={bachelorData}
                    bachelorContent={bachelorContent}
                    master={masterData}
                    masterContent={masterContent}
                />
                <Spacer s="larger"/>

                {/*<Visits data={visitData} links={visitLinks}/>*/}
                {/*<Spacer s="larger"/>*/}

                <AwardsList projects={projects}/>
                <Spacer s="large"/>

                {/*<Events data={eventsData} events={events}/>*/}
                {/*<Spacer s="large"/>*/}
            </Layout>
        </>
    )
}

export default localize(IndexPage)

export const query = graphql`
    {
        sanityPage(title: {fr: {eq: "Accueil"}}) {
            title {
                _type
                en
                fr
            }
            content {
                ... on SanityContentString {
                    content {
                        _type
                        en
                        fr
                    }
                    identification {
                        id
                        section
                    }
                }
                ... on SanityContentText {
                    content {
                        _type
                        en
                        fr
                    }
                    identification {
                        id
                        section
                    }
                }
                ... on SanityContentBlock {
                    content {
                        _rawEn
                        _rawFr
                        _type
                    }
                    identification {
                        id
                        section
                    }
                }
                ... on SanityContentUrl {
                    _key
                    identification {
                        id
                        section
                    }
                    link {
                        _type
                        en
                        fr
                    }
                    text {
                        _type
                        en
                        fr
                    }
                }
                ... on SanityContentImage {
                    identification {
                        id
                        section
                    }
                    credit {
                        name
                    }
                    image {
                        asset {
                            gatsbyImageData(fit: CLIP, placeholder: DOMINANT_COLOR)
                        }
                    }
                    text {
                        _type
                        en
                        fr
                    }
                }
            }
        }
        allSanityEvent(filter: {year: {year: {eq: 2022}}}, sort: {fields: startTime}) {
            nodes {
                place {
                    name {
                        _type
                        en
                        fr
                    }
                    address
                }
                title {
                    _type
                    en
                    fr
                }
                text {
                    _type
                    en
                    fr
                }
                endTime
                startTime
                link
            }
        }
        #        projects
        allSanityProject {
            nodes {
                coverImage {
                    text {
                        _type
                        en
                        fr
                    }
                    credit {
                        name
                    }
                    image {
                        asset {
                            gatsbyImageData(fit: CLIP, placeholder: DOMINANT_COLOR)
                        }
                    }
                }
                title
                slug {
                    current
                }
                id
                tags {
                    name {
                        en
                        fr
                        _type
                    }
                    _id
                }
                awards {
                    name {
                        _type
                        en
                        fr
                    }
                    website
                }
                authors {
                    firstName
                    lastName
                    diploma {
                        shortName {
                            _type
                            en
                            fr
                        }
                        name {
                            _type
                            en
                            fr
                        }
                    }
                }
            }
        }
    }
`
