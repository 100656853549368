import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
  width: 20px;
  height: 20px;
  color: inherit;
  max-height: 25px;
  flex: 0.1;
`;

export const CallToScroll = React.forwardRef((props, ref) => {

    return (<Svg viewBox="0 0 50 100">
            {/*<path ref={ref} d="M555 58V0H246V101H0V235H800V58H555Z" fill="currentColor"/>*/}

            <path
                ref={ref}
                d={props.path}
                fill="currentColor"
                fillRule="nonzero"
                // fill="none"
                // stroke="currentColor"
                // strokeWidth="2"
            />
        </Svg>
    )
});
