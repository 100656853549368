import React, {useState} from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import StandardButton from "../UI/StandardButton";
import {Ex} from "../icons/Ex";
import Spacer from "../layout/Spacer";
import formData from "form-data"
import Mailgun from "mailgun.js"

const Comp = styled.div`

`

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  background-color: ${({theme}) => theme.colors.text};
  width: 100%;
  height: 100%;
  opacity: 0.5;
`

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 999;
  background-color: ${({theme}) => theme.colors.background};
  padding: 2rem;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Close = styled.div`
  background-color: ${({theme}) => theme.colors.accent};
  color: ${({theme}) => theme.colors.background};
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s;
  border: 1px solid ${({theme}) => theme.colors.accent};
  position: absolute;
  right: 10px;
  top: 10px;

  :hover {
    background-color: ${({theme}) => theme.colors.background};
    color: ${({theme}) => theme.colors.accent};
  }
`

const Form = styled.form`
  width: 100%;
  max-width: 20rem;
  display: flex;
  flex-direction: column;
`

const Input = styled.input`
  width: 100%;
  font-family: ${({theme}) => theme.text.small.family};
  font-size: ${({theme}) => theme.text.small.size.regular};
  color: ${({theme}) => theme.colors.accent};
    //border: 1px solid ${({theme}) => theme.colors.accent};
  font-weight: normal;
  border: 0;
  padding: 0.5rem;
`

const Textarea = styled.textarea`
  width: 100%;
  font-family: ${({theme}) => theme.text.small.family};
  font-size: ${({theme}) => theme.text.small.size.regular};
  color: ${({theme}) => theme.colors.accent};
  resize: none;
    //border: 1px solid ${({theme}) => theme.colors.accent};
  font-weight: normal;
  border: 0;
  padding: 0.5rem;
`

const ButtonInput = styled.input`
    //border: 1px solid ${({theme}) => theme.colors.accent};
  border: 0;
  color: ${({theme}) => theme.colors.background};
  background-color: ${({theme}) => theme.colors.text};
  font-family: ${({theme}) => theme.text.standard.family};
  font-size: ${({theme}) => theme.text.standard.size.regular};
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  transition: all 0.25s;
  cursor: ${p => p.active ? "pointer" : "normal"};
  justify-self: center;
  opacity: ${p => p.active ? 1 : 0.2};

  :hover {
    background-color: ${p => p.active ? p.theme.colors.accent : p.theme.colors.text};
    color: ${({theme}) => theme.colors.background};
  }
`

const ContactStudent = (props) => {
        const [open, setOpen] = useState(false)

        const [email, setEmail] = useState("");
        const [message, setMessage] = useState("");

        const [submitted, setSubmitted] = useState(false);
        const [error, setError] = useState(false);
        const [invalidEmail, setInvalidEmail] = useState(false);

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        function toggle() {
            setOpen(!open)
        }

        function inputEmail(e) {
            setEmail(e.target.value);
        }

        function inputMessage(e) {
            setMessage(e.target.value);
        }

        async function sendMessage(data) {
            console.log("send message")
            return true
        }

        function submitMessage(e) {
            console.log("submitted")
            setInvalidEmail(false);
            e.preventDefault();
            if (message !== "" && email !== "") {  // if email and message are not empty
                if (re.test(email)) { // if email is valid
                    setSubmitted(true);

                    // console.log(emails[i]);
                    sendMail({email: email, message: message, recipient: props.address})
                    // console.log(email + " " + message + " -> " + emails);

                    setTimeout(() => {
                        setSubmitted(false);
                        setMessage("");
                        setEmail("");
                    }, 3000);
                } else {
                    setInvalidEmail(true);

                    setTimeout(() => {
                        setInvalidEmail(false);
                    }, 3000);
                }
            }
        }

        const emailText = props.contactData.find(i => i.identification.id === "emailtext").content
        const emailPlaceholder = props.contactData.find(i => i.identification.id === "emailplaceholder").content
        const messageText = props.contactData.find(i => i.identification.id === "messagetext").content
        const messagePlaceholder = props.contactData.find(i => i.identification.id === "messageplaceholder").content

        const mailgun = new Mailgun(formData);

        const mg = mailgun.client({username: 'api', key: process.env.GATSBY_MG_API_KEY, url: 'https://api.eu.mailgun.net'});

        // console.log(props.address)

        function sendMail(data) {
            mg.messages.create(process.env.GATSBY_MG_DOMAIN, {
                from: `Site des diplômes HEAD – Genève <contact@head-geneve.show>`,
                to: props.address,
                subject: `Site des diplômes HEAD – Genève: message de ${email}`,
                text: `
[English Below]
Vous recevez cet email car une personne vous a contacté depuis la plateforme https://head-geneve.show

Vous avez reçu un message de la part de : ${email}

Message :
${message}


Vous pensez avoir reçu ce message par erreur ? Envoyez un mail à l'adresse com.head@hesge.ch

----

You are receiving this email because someone contacted you from the https://head-geneve.show platform.

You received a message from : ${email}

Message :
${message}


You think you are receiving this message by error ? Send a mail to com.head@hesge.ch
	`
            }).then(res => {
                console.log(res)
                setError(false)
            }).catch(err => {
                console.log(err)
                setError(true)
            });
        }

        return (
            <Comp>
                {/*<div onClick={sendMail}>send</div>*/}

                <StandardButton s="small" onClick={toggle}>
                    {props.text} {props.name}
                    {/*<br/>*/}
                    {/*{props.address}*/}
                </StandardButton>

                {open ?
                    <>
                        <ModalBackground onClick={toggle}/>
                        <Modal>
                            <Close onClick={toggle}>
                                <Ex/>
                            </Close>
                            {/*<Spacer s="small"/>*/}
                            <Form
                                onSubmit={email !== "" && message !== "" ? submitMessage : (e) => {
                                    e.preventDefault()
                                }
                                }
                            >
                                <Text s="small">
                                    {emailText}
                                </Text>
                                <Input
                                    // type="email"
                                    placeholder={emailPlaceholder}
                                    value={email}
                                    onChange={inputEmail}
                                />
                                <Spacer s="small"/>

                                <Text s="small">
                                    {messageText}
                                </Text>
                                <Textarea
                                    placeholder={messagePlaceholder}
                                    rows="4"
                                    onChange={inputMessage}
                                    value={message}
                                />
                                <Spacer s="small"/>

                                {submitted && email !== "" && message !== "" ?
                                    <Text s="small">
                                        Message envoyé ! <span role="img" aria-label=":D">😀</span>
                                    </Text>
                                    : invalidEmail ?
                                        <Text s="small">
                                            email invalide <span role="img" aria-label=":|">😐</span>
                                        </Text>
                                        : error ?
                                            <Text s="small">
                                                error <span role="img" aria-label=":|">😐</span>
                                            </Text>
                                            :
                                            <>
                                                {/*<StandardButton s="small">*/}
                                                {/*    Envoyer*/}
                                                {/*</StandardButton>*/}
                                                <Spacer s="small"/>

                                                <ButtonInput
                                                    type="submit"
                                                    value="Envoyer"
                                                    active={email !== "" && message !== ""}
                                                />
                                            </>
                                }
                            </Form>
                            <Spacer s="small"/>
                        </Modal>
                    </>
                    : null}
            </Comp>
        );
    }
;

export default ContactStudent;
