import React from 'react';
import styled from "styled-components";
import BigTitle from "../UI/BigTitle";
import Spacer from "../layout/Spacer";
import Event from "./Event";
import GlobalWidth from "../layout/GlobalWidth";
import {useLanguageContext} from "../../contexts/LanguageContext";
import {groupBy} from 'lodash'
import Text from "../layout/Text";

const Comp = styled(GlobalWidth)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Events = (props) => {
    const {events} = props
    const language = useLanguageContext();
    // const [year, setYear] = useState(new Date().getFullYear());

    const sortedEvents = groupBy(events, event => {
        const dateFormatter = new Intl.DateTimeFormat(
            language.lang, {weekday: "long", year: "numeric", month: "long", day: "numeric"});
        const startDate = new Date(event.startTime)
        // const monthNumber = startDate.getMonth();
        const month = dateFormatter.format(startDate);
        return `${month}`
    });
    // console.log(sortedEvents)

    const eventsComp = Object.keys(sortedEvents).map((item, index) => {
        const events = sortedEvents[item].map((event, index) => {
            const dateFormatter = new Intl.DateTimeFormat(
                language.lang, {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                });
            const start = new Date(event.startTime)
            const end = new Date(event.endTime)

            const eventData = {
                dateTime: dateFormatter.format(start) + " | " + dateFormatter.format(end),
                title: event.title,
                text: event.text,
                place: event.place ? event.place.name ? event.place.name + " - " + event.place.address : event.place.address : "",
                link: event.link
            }

            return (
                <React.Fragment key={index}>
                    <Event
                        dateTime={eventData.dateTime}
                        title={eventData.title}
                        text={eventData.text}
                        place={eventData.place}
                        link={eventData.link}
                    />
                    <Spacer s="medium"/>
                </React.Fragment>
            )
        })
        return (
            <React.Fragment key={index}>
                <Text s="large">
                    {item}
                </Text>
                <Spacer s="medium"/>
                {events}
                <Spacer s="large"/>
            </React.Fragment>
        )
    })

    // console.log(eventsComp)

    return (
        <Comp>
            <BigTitle>
                {props.data.find(item => item.identification.id === "title").content}
            </BigTitle>
            <Spacer s="large"/>

            {eventsComp}
        </Comp>
    );
};

export default Events;
