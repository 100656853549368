import React from 'react';
import styled from "styled-components";
import BigTitle from "../UI/BigTitle";
import Spacer from "../layout/Spacer";
import FieldCard from "../UI/FieldCard";
import GlobalWidth from "../layout/GlobalWidth";

const Comp = styled(GlobalWidth)`
  display: flex;
  flex-direction: column;
  color: ${({theme}) => theme.colors.text};
  width: 100%;
  align-items: center;
`

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, ${({theme}) => theme.cards.standardSize});
  grid-gap: 1.5rem;
  width: 100%;
  justify-content: center;
  //flex-direction: row;
  color: ${({theme}) => theme.colors.text};
  //flex-wrap: wrap;
  //justify-content: flex-start;
  //align-items: flex-start;

  @media (max-width: ${({theme}) => theme.breakPoints.xs}) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`

const Diploma = (props) => {

    const cards = props.cards.map((item, index) => {
        return (
            <FieldCard
                title={item.title}
                image={item.image}
                link={item.link}
                index={index}
                key={index}
                credit={item.credit}
            />
        )
    })

    return (
        <Comp>
            <BigTitle>
                {props.title}
            </BigTitle>
            <Spacer s="large"/>

            <CardContainer>
                {cards}
            </CardContainer>
        </Comp>
    );
};

export default Diploma;
