import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
  height: 20px;
  width: auto;
  color: inherit;
  margin-bottom: 3px;
`;

export const ExternalLink = (props) => (
    <Svg viewBox="0 0 20 20">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.53774 9.99446L10.0051 11.4619L17.917 3.55001L17.8996 8.17416L19.9703 8.16664L19.9996 0L11.833 0.0292768L11.8254 2.10002L16.4496 2.08261L8.53774 9.99446ZM9.95411 2.42799V4.92958H2.48853V17.4383H14.9308V9.93314H17.4193V19.9399H0V2.42799H9.95411Z"
            fill="currentColor"/>

    </Svg>
);
