import React from 'react';
import styled from "styled-components";
import {GatsbyImage} from "gatsby-plugin-image";
import CreditImage from "../UI/CreditImage";

const Comp = styled.div`
    // filter: ${({theme}) => theme.filters.cardDropShadow};
    //border-radius: ${({theme}) => theme.radii.standard};
    // background-color: ${({theme}) => theme.colors.background};
  overflow: hidden;

  position: relative;
  width: 15vw;
  max-width: ${({theme}) => theme.cards.standardSize};
  max-height: ${({theme}) => theme.cards.standardSize};
  min-width: ${({theme}) => theme.cards.smallerSize};
  min-height: ${({theme}) => theme.cards.smallerSize};
  padding-top: 100%;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    // prev m
    width: ${({theme}) => theme.cards.smallSize};
    height: ${({theme}) => theme.cards.smallSize};
  }
`

const Image = styled(CreditImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute !important;
  top: 0;
  left: 0;
  //z-index: 50;

  img {
    object-position: ${p => p.hotspot ? p.hotspot.x * 100 : 50}% ${p => p.hotspot ? p.hotspot.y * 100 : 50}%;
  }
`

const Gradient = styled.div`
  position: absolute;
  background-color: ${({theme}) => theme.colors.accent};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: ${p => p.hover ? 0.85 : 0};
  transition: all 0.25s;
`

const ProjectCard = (props) => {
    // console.log(props.data.coverImage.image)
    return (
        <Comp onMouseEnter={() => props.setHover(true)} onMouseLeave={() => props.setHover(false)}>
            <Gradient className='gradient' hover={props.hover}/>
            {props.data.coverImage && props.data.coverImage.image && props.data.coverImage.image.asset ?
                <Image
                    hotspot={props.data.coverImage.image.hotspot}
                    alt={props.data.coverImage.text}
                    image={props.data.coverImage.image.asset.gatsbyImageData}
                    credit={props.data.coverImage.credit}
                />
                : null}
        </Comp>
    );
};

export default ProjectCard;
