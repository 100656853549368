import React, {createContext, useContext, useEffect, useState} from "react";

export const VariationContext = createContext();

// We wrap the provider in a nice little component
// which will hold the state and provide methods to
// update the state
function VariationProvider(props) {
    const hasWindow = typeof window !== "undefined";

    const [live, setLive] = useState(2);

    useEffect(() => {
        if (hasWindow) {
            setLive(Math.floor(Math.random() * 3));
            // console.log(liveColor);
        }
    }, [hasWindow]);

    const variationData = {live};

    return <VariationContext.Provider value={variationData} {...props} />;
}

// Here we create a custom hook that allows us to consume
// the context
function useVariationContext() {
    return useContext(VariationContext);
}

export {VariationProvider, useVariationContext};
