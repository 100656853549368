import React from 'react';
import styled from "styled-components";
import SplashProject from "./SplashProject";
import Spacer from "../../layout/Spacer";

const Comp = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding-top: ${p => p.top ? p.theme.sizes.headerHeight : 0};
`

const SplashProjects = (props) => {
    const {projects, top} = props;

    const theProjects = projects ? projects.map((item, index) => {
        return (
            <React.Fragment key={index}>
                <SplashProject index={index} data={item} top={top}/>
                <Spacer s="medium"/>
            </React.Fragment>
        )
    }) : null

    return (
        <Comp top={top}>
            {theProjects}
        </Comp>
    );
};

export default SplashProjects;
