import React from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import Link from "../localization/Link";
import Spacer from "../layout/Spacer";
import CreditImage from "./CreditImage";

const Comp = styled(Link)`
    //width: ${({theme}) => theme.cards.standardSize};
  height: 100%;
  width: 100%;
  background-size: 100%;
  background-position: center;
  transition: all 0.25s;
  cursor: pointer;
  //overflow: hidden;

  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;

  align-self: ${p => p.index % 2 === 0 ? "flex-start" : "flex-end"};

  :hover {

    .text {
      transition: all 0.25s;
      color: ${({theme}) => theme.colors.accent};
    }

    .image {
      opacity: 0.15;
    }
  }

  //:hover div {
  //  background: linear-gradient(180deg, rgba(24, 91, 224, 0) 58.85%, #F8F9FA 77.6%)
  //}
`

const Image = styled(CreditImage)`
    // width: ${({theme}) => theme.cards.standardSize};
  height: ${({theme}) => theme.cards.standardSize};
  object-fit: cover;
  flex: 1;
  transition: all 0.25s;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
      //width: calc(${({theme}) => theme.cards.smallerSize} + 5vw);
    max-height: ${({theme}) => theme.cards.smallerSize};
    padding-bottom: 100%;
  }
`

const ImagePlaceHolder = styled.div`
  height: ${({theme}) => theme.cards.standardSize};
  background-color: white;
  flex: 1;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    width: 100%;
      //width: calc(${({theme}) => theme.cards.smallerSize} + 5vw);
    max-height: ${({theme}) => theme.cards.smallerSize};
    padding-bottom: 100%;
  }
`

const Caption = styled(Text)`
  flex: 0.5;
  color: ${({theme}) => theme.colors.text};
`

const Color = styled.div`
  background-color: ${({theme}) => theme.colors.accent};
`

const FieldCard = (props) => {

    return (
        <Comp
            index={props.index}
            to={props.link}
            // image={props.image}
        >
            {/*<Gradient className="gradient"/>*/}

            {props.image ?
                <Color className="color">
                    <Image
                        rel
                        alt={props.title}
                        image={props.image}
                        className="image"
                        credit={props.credit}
                    />
                </Color>
                : <ImagePlaceHolder/>}
            <Spacer s="small"/>

            <Caption s="medium" className="text">
                {props.title}
            </Caption>
        </Comp>
    );
};

export default FieldCard;
