import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {gsap} from "gsap";
import {Draggable} from "gsap/Draggable";
import {InertiaPlugin} from "gsap/InertiaPlugin"
import SplashMobileProject from "./SplashMobileProject";

const Comp = styled.div`
  //overflow: hidden;
  //width: 100%;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  height: 100%;
  width: 100%;
  //overflow: hidden;
`

const SplashMobileProjects = React.forwardRef((props, ref) => {
    const {setSlide} = props
    const {forthRef, backRef} = ref.current;
    const projects = props.projects
    const dragRef = useRef()
    const proxy = useRef()
    const slidesRef = useRef([])
    const [isSliding, setIsSliding] = useState(false)

    useEffect(() => {
        slidesRef.current = slidesRef.current.slice(0, projects.length);
    }, [projects]);

    const slides = projects ? projects.map((item, index) => {
        return (
            <SplashMobileProject
                key={index}
                ref={el => slidesRef.current[index] = el}
                project={item}
                isSliding={isSliding}
            />
        )
    }) : null

    useEffect(() => {
        if (slidesRef.current.length > 0) {
            gsap.registerPlugin(Draggable, InertiaPlugin)

            const slideDuration = 0.3;

            const numSlides = slidesRef.current.length;

            for (let i = 0; i < numSlides; i++) {
                gsap.set(slidesRef.current[i], {
                    // backgroundColor: '#' + (Math.random() * 0xFFFFFF << 0).toString(16),
                    xPercent: i * 100
                });
            }

            // const timer = gsap.delayedCall(slideDelay, autoPlay);

            const animation = gsap.to(slidesRef.current, {
                duration: 1,
                xPercent: "+=" + (numSlides * 100),
                ease: "none",
                paused: true,
                repeat: -1,
                modifiers: {
                    xPercent: gsap.utils.wrap(-100, (numSlides - 1) * 100)
                }
            });

            gsap.set(proxy.current, {x: 0});
            let slideAnimation = gsap.to({}, {duration: 0.1});
            let slideWidth = 0;
            let wrapWidth = 0;
            resize();

            new Draggable(proxy.current, {
                trigger: dragRef.current,
                type: "x",
                allowNativeTouchScrolling: true,
                lockAxis: true,
                onLockAxis: () => {
                },
                // bounds: {target: dragRef.current},
                // bounds: {width: 0},
                zIndexBoost: false,
                // onPress: updateDraggable,
                onDrag: () => updateProgress(),
                onThrowUpdate: () => updateProgress(),
                onDragStart: () => setIsSliding(true),
                onThrowComplete: () => setIsSliding(false),
                // allowEventDefault: true,
                inertia: true,
                edgeResistance: .5,
                overshootTolerance: 0,
                maxDuration: 0.5,
                // throwResistance: 2000,
                snap: {
                    x: value => gsap.utils.snap(slideWidth, value)
                }
                // onClick: function () {
                //     console.log("clicked");
                // },
                // onDragEnd: function () {
                //     console.log("drag ended");
                // }
            });

            window.addEventListener("resize", resize);

            if (forthRef && backRef) {
                forthRef.current.addEventListener("click", goLeft);

                backRef.current.addEventListener("click", goRight);
            }

            // function updateDraggable() {
            //     // timer.restart(true);
            //     slideAnimation.kill();
            //     this.update();
            // }

            function animateSlides(direction) {
                // timer.restart(true);
                slideAnimation.kill();

                const x = snapX(gsap.getProperty(proxy.current, "x") + direction * slideWidth);

                slideAnimation = gsap.to(proxy.current, {
                    duration: slideDuration,
                    x: x,
                    onUpdate: updateProgress,
                    onStart: () => setIsSliding(true),
                    onComplete: () => setIsSliding(false)
                });
            }

            function goLeft() {
                animateSlides(1);
            }

            function goRight() {
                animateSlides(-1);
            }

            function updateProgress() {
                // console.log(gsap.getProperty(proxy.current, "x"))
                animation.progress(gsap.utils.wrap(0, 1, gsap.getProperty(proxy.current, "x") / wrapWidth))
                setSlide(gsap.utils.wrap([1, 2, 3, 4], -Math.round((gsap.getProperty(proxy.current, "x") / wrapWidth) * 4)))
            }

            function resize() {
                const norm = (gsap.getProperty(proxy.current, "x") / wrapWidth) || 0;

                slideWidth = slidesRef.current[0] ? slidesRef.current[0].offsetWidth : 0;
                wrapWidth = slideWidth * numSlides;

                gsap.set(proxy.current, {
                    x: norm * wrapWidth
                });

                animateSlides(0);
                slideAnimation.progress(1);
            }

            function snapX(x) {
                return Math.round(x / slideWidth) * slideWidth;
            }

        }

        return () => {
            // if (forthRef.current) {
            //     forthRef.current.removeEventListener("click", goLeft);
            // }
            //
            // backRef.current.removeEventListener("click", goRight);
        }
    }, [projects, forthRef, backRef, setSlide])

    return (
        <>
            {/*<div onClick={goLeft}>left</div>*/}
            {/*<div onClick={goRight}>right</div>*/}
            <Comp ref={dragRef}>
                <div ref={proxy}
                    // style={{
                    //     backgroundColor: "red",
                    //     width: "20px",
                    //     height: "20px",
                    //     position: "absolute",
                    //     top: 0,
                    //     left: 0,
                    //     zIndex: 100
                    // }}
                />
                {slides}
            </Comp>
        </>
    );
});

export default SplashMobileProjects;
