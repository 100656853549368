import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import CallToScrollAnimation from "../../animations/CallToScrollAnimation";
import useSplashProjects from "../../../hooks/useSplashProjects";
import Text from "../../layout/Text";
import {SelectArrow} from "../../icons/SelectArrow";
import Spacer from "../../layout/Spacer";
import SplashMobileProjects from "./SplashMobileProjects";
import {ReloadArrow} from "../../icons/ReloadArrow";
import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";

const Comp = styled.div`
  width: 100%;
  height: 100vh;
  max-height: fill-available;
  //max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  //overflow: hidden;
  position: relative;
`

const Infos = styled.div`
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  z-index: 2;
  flex: 0 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${p => p.theme.sizes.headerHeight};
  background-color: ${({theme}) => theme.colors.background};
`

const TagContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  display: flex;
  padding: 0 0 1rem 0;
`

const TheTag = styled.div`
  text-align: center;
  //word-break: break-word;
  text-transform: uppercase;
  font-family: ${({theme}) => theme.fonts.GtAmericaExpanded.family};
  font-size: 7.5vw;
`

const Pagination = styled(Text)`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ProjectsWrapper = styled.div`
  width: 100%;
  flex: 1 1 0;
  position: relative;
  overflow: hidden;
`

// const NewTag = styled.div`
//   //margin: 0.5rem 0;
//   text-transform: uppercase;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `

const SplashMobile = (props) => {
    const [randomTag, tag, selProjects] = useSplashProjects(props.projects)
    const [currentSlide, setCurrentSlide] = useState(1)

    const forthRef = useRef()
    const backRef = useRef()
    const ref = useRef({forthRef, backRef})
    const tagRef = useRef()
    const reloadArrowRef = useRef()

    const [tagText, setTagText] = useState("")

    useEffect(() => {
        setTagText(tag ? tag.name : "")
    }, [tag])

    useEffect(() => {
        if (tagText.length > 0) {
            tagRef.current.innerHTML = "#" + tagText
            const split = new SplitText(tagRef.current);
            gsap.from(split.chars, {duration: 0.5, y: 200, stagger: 0.05});
            gsap.fromTo(reloadArrowRef.current, {rotateZ: -360, y: 200}, {duration: 0.5, rotateZ: 0, y: 0});
        }
        // gsap.to(tagRef.current, {
        //     duration: 1,
        //     text: tagText
        // });
    }, [tag, tagText])

    return (
        <Comp>
            <Infos>
                <TagContainer onClick={randomTag}>
                    <TheTag ref={tagRef} s='large'>
                        #{tag ? tag.name : null}
                        {/*#Sustainability*/}
                    </TheTag>
                    <Spacer s="small"/>
                    <ReloadArrow ref={reloadArrowRef}/>
                </TagContainer>

                {/*<NewTag >*/}
                {/*    Nouveau Tag*/}
                {/*    <div style={{width: "10px"}}/>*/}
                {/*    <ReloadArrow/>*/}
                {/*</NewTag>*/}

                <Pagination s='small'>
                    <div ref={forthRef}>
                        <SelectArrow r="-90deg"/>
                    </div>
                    <Spacer s="small"/>
                    {currentSlide}/4
                    <Spacer s="small"/>
                    <div ref={backRef}>
                        <SelectArrow r="90deg"/>
                    </div>
                </Pagination>
            </Infos>
            <ProjectsWrapper>
                {selProjects ?
                    <SplashMobileProjects
                        projects={selProjects}
                        ref={ref}
                        setSlide={setCurrentSlide}
                    />
                    : null}
            </ProjectsWrapper>

            <CallToScrollAnimation data={props.introData}/>
        </Comp>
    );
};

export default SplashMobile;
