import React from 'react';
import styled from "styled-components";
import {Facebook} from "../../icons/Facebook";
import {Twitter} from "../../icons/Twitter";
import {Youtube} from "../../icons/Youtube";
import {Instagram} from "../../icons/Instagram";
import {Linkedin} from "../../icons/Linkedin";
import Link from "../../localization/Link";

const Comp = styled.div`

`

const SocialIcon = styled.div`
  height: 25px;
`;

const FooterLink = styled(Link)`
  transition: all 0.25s;
  :hover {
    color: ${({theme}) => theme.colors.accent};
  }
`

const SocialMediaItem = (props) => {
    const icons = {
        facebook: <Facebook/>,
        twitter: <Twitter/>,
        youtube: <Youtube/>,
        instagram: <Instagram/>,
        linkedin: <Linkedin/>
    }

    return (
        <Comp>
            <SocialIcon>
                <FooterLink
                    to={props.link}
                    title={props.text}
                >
                    {icons[props.media]}
                </FooterLink>
            </SocialIcon>
        </Comp>
    );
};

export default SocialMediaItem;
