import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {CallToScroll} from "../icons/CallToScroll";
import {gsap} from "gsap";
import Text from "../layout/Text";
import Spacer from "../layout/Spacer";

const Comp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //flex-direction: column;
  align-self: center;
  width: 100%;
  z-index: 1;
  background-color: ${({theme}) => theme.colors.background};
  padding: 0.5rem 0;
  position: fixed;
  bottom: 0;
  left: 0;
  transition: all 0.25s;
  opacity: ${p => p.visible ? 1 : 0};
  z-index: ${p => p.visible ? 10 : -9999};
`

const Container = styled.div`
  display: flex;
  width: fit-content;
`

const Phrase = styled(Text)`
  white-space: nowrap;
  flex: 1;
`

const CallToScrollAnimation = (props) => {
    const ref = useRef()
    const path0 = "M 25 100 C 11 100 0 89 0 75 V 75 C 0 61 11 50 25 50 C 39 50 50 61 50 75 V 75 C 50 89 39 100 25 100 Z"
    const path1 = "M 25 100 C 11 100 0 89 0 75 V 25 C 0 11 11 0 25 0 C 39 0 50 11 50 25 V 75 C 50 89 39 100 25 100 Z"
    const path2 = "M 25 50 C 11 50 0 39 0 25 V 25 C 0 11 11 0 25 0 C 39 0 50 11 50 25 V 25 C 50 39 39 50 25 50 Z"

    const [visible, setVisible] = useState(true)

    useEffect(() => {
        const tl = gsap.timeline({repeat: -1, yoyo: false})

        tl.to(ref.current, {
                duration: 0.5,
                attr: {d: path1},
                // color: theme.colors.accent,
                ease: "power3.in"
            }
        )

        tl.to(ref.current, {
                duration: 0.5,
                attr: {d: path2},
                ease: "power3.out"
            }
        )
        tl.to(ref.current, {
                duration: 0.5,
                y: "50",
                ease: "power3.out",
                // color: theme.colors.text,
            }
        )

        return () => {
            tl.kill()
        }
    }, [])

    // disappear on scroll
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= window.innerHeight / 2) {
                setVisible(false)
            } else {
                setVisible(true)
            }
        }

        if (typeof window != "undefined") {
            window.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (typeof window != "undefined") {
                window.removeEventListener("scroll", handleScroll)
            }
        }
    }, [])

    return (
        <Comp visible={visible}>
            <Container>
                <Phrase s="smaller">
                    {props.data.find(item => item.identification.id === "browse next").content.toUpperCase()}
                </Phrase>
                <Spacer s="small"/>
                <CallToScroll path={path0} ref={ref}/>
            </Container>
        </Comp>
    );
};

export default CallToScrollAnimation;
