import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
// import {ReactFitty} from "react-fitty";
import StandardButton from "../../UI/StandardButton";
import BackgroundImageAnim from "./BackgroundImageAnim";
// import {randomInt} from "../../../lib/utils";
import SplashProjects from "./SplashProjects";
import {useSplashContext} from "../../../contexts/SplashContext";
import Spacer from "../../layout/Spacer";
import CallToScrollAnimation from "../../animations/CallToScrollAnimation";
import useSplashProjects from "../../../hooks/useSplashProjects";
// import Text from "../../layout/Text";
import {gsap} from "gsap";
// import {ScrambleTextPlugin} from "gsap/ScrambleTextPlugin"
import {SplitText} from "gsap/SplitText";

const Comp = styled.div`
  height: max(100vh, 1080px);
  width: 100vw;
  //min-height: 920px;
  overflow: hidden;
  position: relative;

  //@media (max-height: 920px) {
  //  position: relative;
  //}
`

const MarginContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-left: ${({theme}) => theme.sizes.globalMargins};
  padding-right: ${({theme}) => theme.sizes.globalMargins};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: relative;
`

const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  //position: relative;
  //z-index: 9;
  flex: 1;
  height: fit-content;

  //div {
  //  //width: auto !important;
  //  flex: 1;
  //}
`

const BigTag = styled.div`
  text-transform: uppercase;
  //word-break: break-word;
  //line-height: 10%;
  //flex: 1;
  //margin-right: 2rem;
  text-align: center;
  font-family: ${({theme}) => theme.fonts.GtAmericaExpanded.family};
  font-size: 7.6vw;
  overflow: hidden;
`

const ButtonContainer = styled.div`
  //flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
`

const Splash = (props) => {
    const splashContext = useSplashContext();
    const [randomTag, tag, selProjects] = useSplashProjects(props.projects)
    const tagRef = useRef()

    const [tagText, setTagText] = useState("")

    useEffect(() => {
        setTagText(tag ? tag.name : "")
    }, [tag])

    useEffect(() => {
        if (tagText.length > 0) {
            tagRef.current.innerHTML = "#" + tagText
            const split = new SplitText(tagRef.current);
            gsap.from(split.chars, {duration: 0.5, y: 200, stagger: 0.05});
        }
        // gsap.to(tagRef.current, {
        //     duration: 1,
        //     text: tagText
        // });
    }, [tag, tagText])

    return (
        <Comp>
            <BackgroundImageAnim projects={selProjects} tag={tag ? tag.name : null}/>

            <MarginContainer>
                <SplashProjects projects={selProjects ? [selProjects[0], selProjects[1]] : null} top/>

                <TagContainer $hover={splashContext.hover}>
                    <BigTag
                        // maxSize={260}
                        // minSize={70}
                        // wrapText={true}
                        s="splashTag"
                        ref={tagRef}
                    >
                        #{tagText}
                        {/*#Interpretation*/}
                    </BigTag>
                    <ButtonContainer>
                        <StandardButton onClick={randomTag}>
                            Nouveau Tag
                        </StandardButton>
                    </ButtonContainer>
                </TagContainer>
                <Spacer s="medium"/>

                <SplashProjects projects={selProjects ? [selProjects[2], selProjects[3]] : null}/>
            </MarginContainer>
            <CallToScrollAnimation data={props.introData}/>
        </Comp>
    );
};

export default Splash;
