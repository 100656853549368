import React from 'react';
import styled from "styled-components";
import localize from "../components/localization/localize";
import {graphql} from "gatsby";
import Layout from "../components/layout/Layout";
import CoverImage from "../components/03_project/CoverImage";
import Spacer from "../components/layout/Spacer";
import ProjectTitle from "../components/03_project/ProjectTitle";
import ProjectContent from "../components/03_project/ProjectContent";
import GlobalWidth from "../components/layout/GlobalWidth";
import ProjectData from "../components/03_project/ProjectData";
import BackToCatalogue from "../components/03_project/BackToCatalogue";
import Conditional from "../components/layout/Conditional";
import ScrollButton from "../components/UI/ScrollButton";

const Container = styled(GlobalWidth)`
  display: flex;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    // prev m
    flex-direction: column;
  }

`

const Project = (props) => {
    const data = props.data.project
    const pageData = props.data.sanityPage

    const backText = pageData.content.find(i => i.identification.section === "back" && i.identification.id === "button").content

    // console.log(data)

    return (
        <Layout
            lang={props.pageContext.locale}
            footerVideo
            title={`${data.authors[0].firstName} ${data.authors[0].lastName}`}
            template
        >
            <CoverImage data={data.coverImage} awards={data.awards}/>
            <Conditional desktop>
                <Spacer s="large"/>
                <Spacer s="medium"/>
            </Conditional>
            <Conditional mobile>
                <Spacer s="medium"/>
            </Conditional>

            <ProjectTitle
                title={data.title}
                name={data.authors[0].firstName + " " + data.authors[0].lastName}
            />
            <Conditional desktop>
                <Spacer s="larger"/>
            </Conditional>
            <Conditional mobile>
                <Spacer s="medium"/>
            </Conditional>

            <Container>
                <ProjectData
                    data={data}
                    pageData={pageData.content}
                    text={data.text}
                    images={data.projectImages}
                    videos={data.projectVideos}
                />
                <Spacer s="medium"/>

                <ProjectContent
                    text={data.text}
                    coverImage={data.coverImage}
                    images={data.projectImages}
                    videos={data.projectVideos}
                />
            </Container>

            <BackToCatalogue text={backText}/>
            <ScrollButton project/>

        </Layout>
    );
};

export default localize(Project);

export const query = graphql`
    query ProjectQuery($id: String!) {
        sanityPage(title: {en: {eq: "Project"}}) {
            content {
                ... on SanityContentString {
                    content {
                        _type
                        en
                        fr
                    }
                    identification {
                        id
                        section
                    }
                }
                ... on SanityContentText {
                    content {
                        _type
                        en
                        fr
                    }
                    identification {
                        id
                        section
                    }
                }
                ... on SanityContentUrl {
                    identification {
                        id
                        section
                    }
                    link {
                        _type
                        en
                        fr
                    }
                    text {
                        _type
                        en
                        fr
                    }
                }
            }
            title {
                _type
                en
                fr
            }
        }
        project: sanityProject(id: {eq: $id}) {
            _id
            authors {
                contactAllowed
                diploma {
                    field {
                        name {
                            _type
                            en
                            fr
                        }
                        _id
                        shortName
                        type
                    }
                    name {
                        _type
                        en
                        fr
                    }
                    _id
                }
                email
                firstName
                lastName
                website
                socialMedia {
                    socialMedia {
                        name
                        baseURL
                    }
                    id
                }
                _id
                option {
                    _id
                    name {
                        _type
                        en
                        fr
                    }
                    diploma {
                        _id
                    }
                }
            }
            awards {
                name {
                    _type
                    en
                    fr
                }
                website
            }
            thesisLink
            coverImage {
                text {
                    _type
                    en
                    fr
                }
                credit {
                    name
                }
                image {
                    crop {
                        bottom
                        left
                        right
                        top
                    }
                    hotspot {
                        height
                        width
                        x
                        y
                    }
                    asset {
                        id
                        gatsbyImageData(fit: CROP, placeholder: DOMINANT_COLOR)
                    }
                }
            }
            media {
                _id
                name {
                    _type
                    fr
                    en
                }
            }
            projectImages {
                text {
                    _type
                    en
                    fr
                }
                credit {
                    name
                }
                image {
                    asset {
                        gatsbyImageData(fit: CLIP, placeholder: DOMINANT_COLOR)
                    }
                }
            }
            projectVideos
            projectUrl
            socialMedia
            tags {
                _id
                name {
                    _type
                    en
                    fr
                }
            }
            title
            text
            tutors {
                name
                _id
            }
        }
    }
`;
