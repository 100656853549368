import React, {useEffect} from 'react';
import styled from "styled-components";
import StandardButton from "../UI/StandardButton";
import Spacer from "./Spacer";
import {useLanguageContext} from "../../contexts/LanguageContext";
import {Link as GatsLink} from "gatsby";
import Text from "./Text";
import Link from "../localization/Link"
// import {HeadLogo} from "../icons/HeadLogo";
import Conditional from "./Conditional";
import HeaderLogo from "./HeaderLogo";
import MenuBackground from "./MenuBackground";

const Comp = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  //transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  //background-image: linear-gradient(rgba(248, 249, 250, 0) 0%, rgba(248, 249, 250, 1) 40%, rgba(248, 249, 250, 1) 60%, rgba(248, 249, 250, 0) 100%);
  z-index: -50;
`

const LangContainer = styled.div`
  display: flex;
  transition: color 0.25s;

  a:hover {
    color: ${({theme}) => theme.colors.accent};
  }
`

const LangLink = styled(GatsLink)`
  text-decoration: ${p => p.selected ? "underline" : null};
`

const MenuLink = styled(Link)`
  :hover {
    color: ${({theme}) => theme.colors.background};
  }
`

function parsePath(lang, path) {
    const pathSplit = path.split("/");
    const elements = pathSplit.filter((str) => {
        return str !== "" && str !== "en" && str !== "fr";
    });

    if (elements.length === 2) {
        return elements[0] + "/" + elements[1];
        // if (elements[1]) {
        // 	return elements[1];
        // } else return "";
    } else {
        if (elements[0]) {
            return elements[0];
        } else return "";
    }
}

const MainMenu = (props) => {
    const language = useLanguageContext();
    const pathName = typeof window !== "undefined" ? window.location.pathname : "";
    const path = parsePath(language, pathName);

    useEffect(() => {
        language.setLang(props.lang);
    }, [language, props.lang]);

    const menu = props.data.map((item, index) => {
        return (
            <React.Fragment key={index}>
                <MenuLink to={item.link[language.lang]}>
                    <StandardButton>
                        {item.text[language.lang]}
                    </StandardButton>
                </MenuLink>
                <Spacer s="small"/>
            </React.Fragment>
        )
    })

    return (
        <>
            {
                props.open ?
                    <Comp onClick={() => props.toggle(false)}>
                        <Conditional mobile>
                            <HeaderLogo/>
                            <Spacer s="medium"/>
                        </Conditional>

                        <LangContainer>
                            <LangLink to={`/${path}`} selected={language.lang === "fr"}>
                                <Text s="standard">
                                    FR
                                </Text>
                            </LangLink>

                            <Spacer s="smaller"/>
                            <Text s="standard">
                                /
                            </Text>
                            <Spacer s="smaller"/>

                            <LangLink to={`/en/${path}`} selected={language.lang === "en"}>
                                <Text s="standard">
                                    EN
                                </Text>
                            </LangLink>
                        </LangContainer>
                        <Spacer s="small"/>

                        {menu}
                        <MenuBackground/>
                    </Comp>
                    : null
            }
        </>
    );
};

export default MainMenu;
