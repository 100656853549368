import React from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import CreditImage from "../UI/CreditImage";

const Comp = styled.div`
  width: 100%;
  height: 70vh;
  //background-color: palegoldenrod;
  //margin-top: 5rem;
  position: relative;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    //margin-top: 0rem;
  }
`

const Image = styled(CreditImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;

  img {
    object-position: ${p => p.hotspot ? p.hotspot.x * 100 : 50}% ${p => p.hotspot ? p.hotspot.y * 100 : 50}%;
  }
`

const RibbonContainer = styled.div`
  position: absolute;
  top: 6rem;
  left: 0;
  display: flex;
  flex-direction: column;
`

const Ribbon = styled.div`
  background-color: ${({theme}) => theme.colors.accent};
  color: ${({theme}) => theme.colors.background};
  z-index: 100;
    //padding: 1rem 2rem 1rem ${({theme}) => theme.sizes.globalMargins};
  margin-bottom: 1rem;
  height: 4rem;
  display: flex;
  align-items: center;
  padding-left: ${({theme}) => theme.sizes.globalMargins};
  padding-right: 2rem;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    border: 2em solid ${({theme}) => theme.colors.accent};
    z-index: -1;
    right: -2em;
    border-left-width: 1.5em;
    border-right-color: transparent;
  }

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    // prev m
    padding: ${({theme}) => theme.spacer.medium[1]};
    width: fit-content;
  }
`

const CoverImage = (props) => {
    // console.log(props.data.image.hotspot)
    // console.log(urlFor(props.data.image.asset.id).width(1920).height(1080).focalPoint(props.data.image.hotspot.x, props.data.image.hotspot.y).fit("crop").crop("focalpoint").url())

    const ribbons = props.awards.map((item, index) => {
        return (
            <Ribbon key={index}>
                <Text s='standard'>
                    {item.name}
                </Text>
            </Ribbon>
        )
    })

    return (
        <Comp>
            <RibbonContainer>
                {ribbons}
            </RibbonContainer>

            {props.data && props.data.image && props.data.image.asset ?
                <Image
                    wh="true"
                    alt={props.data.text}
                    image={props.data.image.asset.gatsbyImageData}
                    hotspot={props.data.image.hotspot}
                    credit={props.data.credit}
                />
                : null}
        </Comp>
    );
};

export default CoverImage;
