import React from "react"
import {ProjectProvider} from "./src/contexts/ProjectsContext";
import Theme from "./src/style/Theme";
import {LanguageProvider} from "./src/contexts/LanguageContext";
import {VariationProvider} from "./src/contexts/VariationContext";

export const wrapRootElement = ({element}) => {

    return (
        <VariationProvider>
            <Theme>
                <LanguageProvider>
                    <ProjectProvider>
                        {element}
                    </ProjectProvider>
                </LanguageProvider>
            </Theme>
        </VariationProvider>
    )
}
