import React from 'react';
import styled from "styled-components";
import StandardButton from "../UI/StandardButton";
import {SelectArrow} from "../icons/SelectArrow";
import Spacer from "../layout/Spacer";

const Comp = styled.div`
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  z-index: 100;
  color: ${({theme}) => theme.colors.text};
`

const BackToCatalogue = (props) => {
    return (
        <Comp>
            <StandardButton to="/catalogue" s="small">
                <SelectArrow r="-90deg"/> <Spacer s="small"/> {props.text}
            </StandardButton>
        </Comp>
    );
};

export default BackToCatalogue;
