import React from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import Spacer from "../layout/Spacer";
import Video from "../video/Video";
import CreditImage from "../UI/CreditImage";

const Comp = styled.div`
  flex: 1;
`

const ProjectText = styled(Text)`
  white-space: pre-wrap;
`

const ProjectContent = (props) => {
    const videos = props.videos.map((item, index) => {
        return (
            <React.Fragment key={index}>
                {/*<GatsbyImage alt={item.text} image={item.image.asset.gatsbyImageData}/>*/}
                <Video url={item}/>
                <Spacer s="medium"/>
            </React.Fragment>
        )
    })

    const images = props.images.map((item, index) => {
        if (item.image && item.image.asset) {
            return (
                <React.Fragment key={index}>
                    <CreditImage
                        rel={"true"}
                        alt={item.text}
                        image={item.image.asset.gatsbyImageData}
                        credit={item.credit}
                    />
                    <Spacer s="medium"/>
                </React.Fragment>
            )
        } else return null
    })

    return (
        <Comp>
            <ProjectText s="small">
                {props.text}
            </ProjectText>
            <Spacer s="large"/>

            {videos}

            {props.coverImage && props.coverImage.image && props.coverImage.image.asset ?
                <>
                    <CreditImage
                        rel={"true"}
                        alt={props.coverImage.text}
                        image={props.coverImage.image.asset.gatsbyImageData}
                        credit={props.coverImage.credit}
                    />
                    <Spacer s="medium"/>
                </> : null}
            {images}
        </Comp>
    );
};

export default ProjectContent;
