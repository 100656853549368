import React from 'react';
import Text from "../layout/Text";
import DataLink from "./DataLink";

const Thesis = (props) => {
    const {data} = props
    const text = props.text.replace("[student]", data.authors[0].firstName + " " + data.authors[0].lastName)

    return (
        <DataLink to={`${data.thesisLink}`}
                  target="_blank" rel="noopener noreferrer">
            <Text s="small">
                {/*{props.text} {data.authors[0].firstName} {data.authors[0].lastName}*/}
                {text}
            </Text>
        </DataLink>
    );
};

export default Thesis;
