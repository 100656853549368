import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import Text from "../../layout/Text";
import {GatsbyImage} from "gatsby-plugin-image";
import Spacer from "../../layout/Spacer";
import {useSplashContext} from "../../../contexts/SplashContext";
import {Eye} from "../../icons/Eye";
import Link from "../../localization/Link";
import {truncate} from "../../../lib/utils";
import {gsap} from "gsap";

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  z-index: ${p => p.selected && p.hover ? 9 : p.selected && p.anim !== "down" ? 9 : 0};
`

const Animated = styled.div`
  display: flex;
  justify-content: ${p => p.even ? "flex-start" : "flex-end"};
`

const Comp = styled(Link)`
  display: flex;
  width: fit-content;
  flex-direction: ${p => p.even ? "row" : "row-reverse"};
  align-self: ${p => p.even ? "flex-start" : "flex-end"};
  color: ${p => p.hover && p.selected ? p.theme.colors.accent : p.theme.colors.text};
  cursor: pointer;
    // background-color: ${p => p.hover && p.selected ? p.theme.colors.background : p.selected && p.anim !== "down" ? p.theme.colors.background : "transparent"};
  background-color: ${p => p.theme.colors.background};
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: color 0.5s;
`

const ImgContainer = styled.div`
  width: ${p => p.hover ? "0px" : "100px"};
  height: 100px;
    //display: ${p => p.hover ? "none" : "block"};
    //background-color: ${p => p.hover ? p.theme.colors.accent : "transparent"};
  transition: all 0.25s;
`

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
    //  opacity: ${p => p.hover ? 0 : 1};
  //transition: all 5s;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  text-align: ${p => p.even ? "left" : "right"};
  //width: 50%;
  //background-color: palegoldenrod;
`

const TextItem = styled(Text)`
  text-align: inherit;
  width: 100%;
  text-overflow: ellipsis;
`

const AntiJank = styled.div`
  width: ${p => !p.hover ? "0px" : "100px"};
  transition: width 0.25s;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  //background-color: rebeccapurple;
`

const SplashProject = (props) => {
    const {data, top} = props;
    const splashContext = useSplashContext()
    const compRef = useRef()

    const authors = data.authors.map((item, index) => {
        return (
            <React.Fragment key={index}>
                {item.firstName} {item.lastName}
            </React.Fragment>
        )
    })

    const field = data.authors[0].diploma.shortName ? data.authors[0].diploma.shortName : data.authors[0].diploma.name

    const handleMouseEnter = () => {
        // if (splashContext.anim === "down") {
        splashContext.setHover(true)
        splashContext.setID(data.id)
        // }
    }

    const handleMouseLeave = () => {
        // if (splashContext.anim === "up") {
        splashContext.setHover(false)
        // }
    }

    const addedDelay = top ? 0 : 0.5
    useEffect(() => {
        // gsap.fromTo(compRef.current, {y: 0}, {duration: 1, y: 500})
        gsap.fromTo(compRef.current, {y: 500}, {
            duration: 1,
            y: 0,
            delay: (props.index / 4) + addedDelay,
            ease: "power3.out"
        })
    }, [data, addedDelay, props.index])

    return (

        <Container
            selected={splashContext.id === data.id}
        >
            <Animated
                ref={compRef}
                even={props.index % 2 === 0 ? 1 : 0}
            >
                <Comp
                    to={data.slug ? "/projects/" + data.slug.current : null}
                    even={props.index % 2 === 0 ? 1 : 0}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    selected={splashContext.id === data.id}
                    hover={splashContext.hover ? 1 : 0}
                    anim={splashContext.anim}
                >
                    <ImgContainer hover={splashContext.id === data.id && splashContext.hover ? 1 : 0}>
                        {data.coverImage ?
                            <Image
                                $hover={splashContext.id === data.id && splashContext.hover}
                                alt={data.coverImage.text}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                image={data.coverImage.image.asset.gatsbyImageData}
                                className="image"
                            />
                            : null}
                    </ImgContainer>

                    <Spacer s="small"/>

                    <TextContainer even={props.index % 2 === 0 ? 1 : 0}>
                        <TextItem s="cardsField">
                            {field}
                        </TextItem>
                        <TextItem s="cardsName">
                            {authors}
                        </TextItem>
                        <TextItem s="cardsTitle">
                            {truncate(data.title, 50, true)}
                        </TextItem>
                    </TextContainer>

                    <Spacer s="small"/>

                    <AntiJank hover={splashContext.id === data.id && splashContext.hover}>
                        <Eye/>
                    </AntiJank>
                    <Spacer s="small"/>
                </Comp>
            </Animated>
        </Container>
    );
};

export default SplashProject;
