import React from 'react';
import styled from "styled-components";
import {GatsbyImage} from "gatsby-plugin-image";
import Text from "../layout/Text";

const Comp = styled.div`
  position: ${p => p.rel ? "relative" : null};
  width: ${p => p.wh ? "100%" : "auto"};
  height: ${p => p.wh ? "100%" : "auto"};
`

const Image = styled(GatsbyImage)`
`

const Credit = styled(Text)`
  position: absolute;
  right: 0;
  bottom: 0;
  //transform-origin: top right;
  //transform: rotateZ(90deg) translateX(100%);
  background-color: ${({theme}) => theme.colors.background};
  //border-radius: 10px 0 0 0;
  padding-left: 5px;
  //filter: invert(1);
  //mix-blend-mode: difference;
  //color: white;
`

const CreditImage = (props) => {
        const credit = Array.isArray(props.credit) && props.credit.length > 0 ? props.credit.map((item, index) => {
            return (
                <React.Fragment key={index}>
                    {item.name}
                    {index < (props.credit.length - 1) ? ", " : null}
                </React.Fragment>
            )
        }) : null

        return (
            <Comp rel={props.rel ? "true" : null} wh={props.wh ? "true" : null}>
                <Image {...props}/>
                {credit ? <Credit s="smallest">© {credit}</Credit> : null}
            </Comp>
        );
    }
;

export default CreditImage;
