import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import styled from "styled-components";
import {gsap} from "gsap";
import {Building} from "../icons/buildingShapes/Building";

const Comp = styled.div`
  cursor: pointer;
  width: 100%;
  transition: color 0.5s;

  :hover {
    color: ${({theme}) => theme.colors.accent};
  }
`

const BuildingsAnimation = (props) => {
    const buildingRef = useRef();
    const phraseRef = useRef();
    const ref = useRef({buildingRef, phraseRef});

    // const pathHBox = "M 555 0 V 0 H 246 v 0 H 0 V 235 H 800 V 0 Z"
    // const pathE1 = "M 800 0 H 0 V 166 H 78 L 47 235 H 111 L 146 166 H 246 V 235 H 555 V 166 H 656 L 691 235 H 755 L 724 166 H 800 Z"

    const pathH = "M 555 58 L 555 0 L 246 0 L 246 101 L 0 101 L 0 235 L 76 235 L 76 235 L 144 235 L 144 235 L 245 235 L 245 235 L 555 235 L 555 235 L 656 235 L 656 235 L 724 235 L 724 235 L 800 235 L 800 58 L 555 58 Z"
    const pathHE = "M 555 69 L 555 69 L 246 69 L 246 69 L 0 69 L 0 235 L 76 235 L 76 235 L 144 235 L 144 235 L 245 235 L 245 235 L 555 235 L 555 235 L 656 235 L 656 235 L 724 235 L 724 235 L 800 235 L 800 69 L 555 69 Z"
    const pathE = "M 555 0 L 555 0 L 246 0 L 246 0 L 0 0 L 0 166 L 76 166 L 41 234 L 107 234 L 144 166 L 245 166 L 245 235 L 555 235 L 555 166 L 656 166 L 691 235 L 755 235 L 724 166 L 800 166 L 800 0 L 555 0 Z"
    const pathEA2 = "M 555 0 L 555 0 L 246 0 L 246 0 L 0 0 L 0 166 L 76 166 L 76 166 L 144 166 L 144 166 L 245 166 L 245 166 L 555 166 L 555 166 L 656 166 L 656 166 L 724 166 L 724 166 L 800 166 L 800 0 L 555 0 Z"
    const pathEA3 = "M 691 0 L 279 0 L 111 0 L 111 0 L 0 0 L 0 166 L 111 166 L 111 166 L 111 166 L 111 166 L 111 166 L 279 166 L 691 166 L 691 166 L 691 166 L 691 166 L 691 166 L 691 166 L 800 166 L 800 0 L 691 0 Z"
    const pathA = "M 691 0 L 279 43 L 111 15 L 111 15 L 0 15 L 0 220 L 111 220 L 111 220 L 111 220 L 111 220 L 111 220 L 279 202 L 691 235 L 691 235 L 691 235 L 691 235 L 691 235 L 691 235 L 800 235 L 800 0 L 691 0 Z"
    const pathAD1 = "M 691 101 L 279 101 L 111 101 L 111 101 L 0 101 L 0 235 L 111 235 L 111 235 L 111 235 L 111 235 L 111 235 L 279 235 L 691 235 L 691 235 L 691 235 L 691 235 L 691 235 L 691 235 L 800 235 L 800 101 L 691 101 Z"
    // const pathAD1dot5 = "M 278 202 L 146 202 C 146 183 136 168 125 159 L 21 159 C 10 168 0 183 0 202 L 0 235 L 111 235 L 111 235 L 111 235 L 111 235 L 400 235 L 800 235 L 800 202 C 800 183 790 168 779 159 L 675 159 C 664 168 654 183 654 202 L 522 202 L 522 186 C 522 155 507 115 488 101 L 312 101 C 293 115 278 155 278 186 Z"
    // const pathAD2 = "M 278 101 L 146 101 C 146 101 136 101 125 101 L 21 101 C 10 101 0 101 0 101 L 0 235 L 111 235 L 111 235 L 111 235 L 111 235 L 400 235 L 800 235 L 800 101 C 800 101 790 101 779 101 L 675 101 C 664 101 654 101 654 101 L 522 101 L 522 101 C 522 101 507 101 488 101 L 312 101 C 293 101 278 101 278 101 Z"
    const pathAD_cross = "M 278 202 L 146 202 C 146 183 136 168 125 159 L 21 159 C 10 168 0 183 0 202 L 0 235 L 800 235 L 800 101 L 0 101 L 0 235 L 800 235 L 800 235 L 800 202 C 800 183 790 168 779 159 L 675 159 C 664 168 654 183 654 202 L 522 202 L 522 186 C 522 155 507 115 488 101 L 312 101 C 293 115 278 155 278 186 Z"
    const pathD_cross = "M 278 101 L 146 101 C 146 82 136 67 125 58 L 21 58 C 10 67 0 82 0 101 L 0 235 L 800 235 L 800 101 L 0 101 L 0 235 L 800 235 L 800 235 L 800 101 C 800 82 790 67 779 58 L 675 58 C 664 67 654 82 654 101 L 522 101 L 522 85 C 522 54 507 14 488 0 L 312 0 C 293 14 278 54 278 85 Z"
    const pathD = "M 278 101 L 146 101 C 146 82 136 67 125 58 L 21 58 C 10 67 0 82 0 101 L 0 235 L 111 235 L 111 235 L 111 235 L 111 235 L 400 235 L 800 235 L 800 101 C 800 82 790 67 779 58 L 675 58 C 664 67 654 82 654 101 L 522 101 L 522 85 C 522 54 507 14 488 0 L 312 0 C 293 14 278 54 278 85 Z"
    const pathDH1 = "M 278 0 L 146 0 C 146 0 136 0 125 0 L 21 0 C 10 0 0 0 0 0 L 0 235 L 111 235 L 111 235 L 111 235 L 111 235 L 400 235 L 800 235 L 800 0 C 800 0 790 0 779 0 L 675 0 C 664 0 654 0 654 0 L 522 0 L 522 0 C 522 0 507 0 488 0 L 312 0 C 293 0 278 0 278 0 Z"
    // 0s transition
    const pathDH2 = "M 555 0 L 555 0 L 246 0 L 246 0 L 0 0 L 0 235 L 76 235 L 76 235 L 144 235 L 144 235 L 245 235 L 245 235 L 555 235 L 555 235 L 656 235 L 656 235 L 724 235 L 724 235 L 800 235 L 800 0 L 555 0 Z"

    const changeCall = useRef();

    // const tl = gsap.timeline({
    //     // repeat: 1,
    //     // repeatDelay: .5,
    //     // yoyo: true
    //     // onComplete: () => tl.invalidate().restart()
    //     // onComplete: () => changeCall = gsap.delayedCall(delay, createAnimBuildings)
    //
    // })

    const duration = 0.55
    const intermediaryEase = "power4.in"
    const stepEase = "power4.out"
    const phraseOffset = 20

    let anim = useMemo(() => [
        [
            {path: pathHE, duration: duration, ease: intermediaryEase},

            {path: pathE, duration: duration, ease: stepEase},
            {path: "phrase", duration: duration, y: 68 + phraseOffset, ease: stepEase},
        ],
        [
            {path: pathEA2, duration: duration, ease: intermediaryEase},
            {path: pathEA3, duration: 0, ease: "none"},

            {path: pathA, duration: duration, ease: stepEase},
            {path: "phrase", duration: duration, y: 107 + phraseOffset, ease: stepEase},
        ],
        [
            {path: pathAD1, duration: duration, ease: intermediaryEase},
            {path: pathAD_cross, duration: 0, ease: "none"},
            {path: pathD_cross, duration: duration, ease: stepEase},
            {path: "phrase", duration: duration, y: 153 + phraseOffset, ease: stepEase}
        ],
        [
            {path: pathD, duration: 0, ease: "none"},
            {path: pathDH1, duration: duration, ease: intermediaryEase},
            {path: pathDH2, duration: 0, ease: "none"},
            {path: pathH, duration: duration, ease: stepEase},
        ]
    ], [])

    const createAnimBuildings = useCallback(() => {
        const tl = gsap.timeline({})

        let fullDuration = 0

        for (let i = 0; i < anim[0].length; i++) {
            if (anim[0][i].path !== "phrase") {
                tl.to(buildingRef.current, {
                        duration: anim[0][i].duration,
                        attr: {d: anim[0][i].path},
                        ease: anim[0][i].ease,
                    }
                )

                fullDuration += anim[0][i].duration
            } else {
                tl.to(phraseRef.current, {
                        duration: anim[0][i].duration,
                        y: anim[0][i].y,
                        ease: anim[0][i].ease,
                    },
                    "<"
                )
            }
        }
        anim.push(anim.shift())

        changeCall.current = gsap.delayedCall(fullDuration, createAnimBuildings)
    }, [anim])

    // const createAnimPhrase = useCallback(() => {
    //
    // }, [])

    useEffect(() => {
        gsap.timeline({}).to(phraseRef.current, {
                duration: 0,
                y: "153",
            }
        )
        changeCall.current = gsap.delayedCall(0, createAnimBuildings)

        return () => {
            changeCall.current.kill();
        }
    }, [createAnimBuildings])

    const playTimeline = () => {
        // tl.play()
        changeCall.current = gsap.delayedCall(0, createAnimBuildings)
    }

    const handleMouseEnter = () => {
        // if (tl.isActive()) {
        // tl.pause()
        // }
        changeCall.current.kill();
        // console.log(tl.getActive())
    }

    return (
        <Comp
            onMouseEnter={handleMouseEnter}
            onMouseLeave={playTimeline}
            // onClick={playTimeline}
        >
            <Building path={pathH} ref={ref} text={props.text}/>
            {/*<Phrase s="larger" c="background" ref={phraseRef}>*/}
            {/*    Voir tous les projets*/}
            {/*</Phrase>*/}
        </Comp>
    );
};

export default BuildingsAnimation;
