import React, {useState} from 'react';
import styled from "styled-components";
import Award from "./Award";
import Text from "../layout/Text";
import Spacer from "../layout/Spacer";
import CreditImage from "../UI/CreditImage";
import Link from "../localization/Link";

const Comp = styled.div`
  display: flex;
  color: ${p => p.hoverAward ? p.theme.colors.text + "!important" : "inherit"};

  :hover {
    color: ${({theme}) => theme.colors.accent}
  }

  // :hover .award {
    //   color: ${({theme}) => theme.colors.text}
  // }

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    flex-direction: column;
  }
`

const Infos = styled.div`
`

const InfoLink = styled(Link)`
`

const ImageContainer = styled(Link)`
  width: 10rem;
  height: 10rem;
  //background-color: crimson;
  flex-shrink: 0;
  position: relative;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    width: 100%;
    height: auto;
    :after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
`

const Image = styled(CreditImage)`

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const AwardProject = (props) => {
    const {data} = props;
    const [hoverAward, setHoverAward] = useState(false)

    const awards = data.awards.map((item, index) => {
        return (
            <Award hover={hoverAward} setHover={setHoverAward} key={index} data={item}/>
        )
    })
    const authors = data.authors.map((item, index) => {
        return (
            <Text key={index} s="cardsName">{item.firstName} {item.lastName}</Text>
        )
    })

    // console.log(data)

    return (
        <Comp
            hoverAward={hoverAward}
        >
            <ImageContainer
                to={"/projects/" + data.slug.current}
            >
                <Image
                    wh={"true"}
                    // rel
                    alt={data.coverImage.text}
                    image={data.coverImage.image.asset.gatsbyImageData}
                    className="image"
                />
            </ImageContainer>
            <Spacer s="small"/>

            <Infos
            >
                {awards}
                <Spacer s="small"/>

                <InfoLink
                    to={"/projects/" + data.slug.current}
                >
                    {authors}
                    <Spacer s="smaller"/>
                    <Text s="cardsTitle">{data.title}</Text>
                    <Spacer s="small"/>

                    <Text
                        s="cardsField"
                    >
                        {data.authors[0].diploma.shortName ? data.authors[0].diploma.shortName : data.authors[0].diploma.name}
                    </Text>
                </InfoLink>
            </Infos>
        </Comp>
    );
};

export default AwardProject;
