import React from 'react';
import styled from "styled-components";
// import Text from "../layout/Text";
import Spacer from "../layout/Spacer";
import GlobalWidth from "../layout/GlobalWidth";
import BuildingsAnimation from "../animations/BuildingsAnimation";
import Link from "../localization/Link";

const Comp = styled(GlobalWidth)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BuildingLink = styled(Link)`
  width: 100%;
`

const Edito = (props) => {

    return (
        <Comp>
            <Spacer s="large"/>
            {/*<StandardButton to="/catalogue">*/}
            {/*    {props.data.find(item => item.identification.id === "browse button").content}*/}
            {/*</StandardButton>*/}
            <BuildingLink to="/catalogue">
                <BuildingsAnimation text={props.data.find(item => item.identification.id === "browse button").content}/>
            </BuildingLink>
            <Spacer s="larger"/>

            {/*<Text s="standard">*/}
            {/*    {props.data.find(item => item.identification.id === "text").content}*/}
            {/*    <br/>*/}
            {/*    <br/>*/}
            {/*    {props.data.find(item => item.identification.id === "signature").content}*/}
            {/*</Text>*/}
            {/*<Spacer s="larger"/>*/}
        </Comp>
    );
};

export default Edito;
