import React from 'react';
import styled from "styled-components";
import {HeadLogo} from "../icons/HeadLogo";

const Comp = styled.a`
  transition: all 0.25s;
  :hover{
    color: ${({theme}) => theme.colors.accent};
  }
`

const HeaderLogo = (props) => {
    return (
        <Comp href="https://www.hesge.ch/head" target="_blank" rel="noopener noreferrer">
            <HeadLogo/>
        </Comp>
    );
};

export default HeaderLogo;
