import React from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import {ExternalLink} from "../icons/ExternalLink";
import Spacer from "../layout/Spacer";
import Link from "../localization/Link";

const Comp = styled(Link)`
  display: flex;
  align-items: center;
  color: ${p => p.hover && p.website ? p.theme.colors.accent : p.theme.colors.text} !important;

  cursor: ${p => p.website ? "pointer" : "default"};
`

const Award = (props) => {
        const {data, hover, setHover} = props;

        function mouseEnter() {
            setHover(true)
            // console.log("enter")
        }

        function mouseLeave() {
            setHover(false)
        }

        return (
            <Comp
                to={data.website}
                className="award"
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
                hover={hover}
                website={data.website}
            >
                <Text s="cardsTitle" t="uppercase">{data.name}</Text>
                {data.website ?
                    <>
                        <Spacer s="small"/>
                        <ExternalLink/>
                    </>
                    : null}
            </Comp>
        );
    }
;

export default Award;
