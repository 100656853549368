import React from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import Link from "../localization/Link";

const Comp = styled(Link)`
  // border: 1px solid ${p => p.light ? p.theme.colors.text : p.lemon ? p.theme.colors.lemon : p.theme.colors.background};
  padding: calc(0.5rem + 2px) 1.5rem 0.5rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  transition: all 0.25s;
    //background-color: ${({theme}) => theme.colors.text};
  width: fit-content;
  height: fit-content;
  color: ${p => p.light ? p.theme.colors.text : p.lemon ? p.theme.colors.lemon : p.theme.colors.background};
  //border-radius: 5px;
  background-color: ${p => p.light ? p.theme.colors.text : p.lemon ? "none" : p.theme.colors.text};

  :hover {
    background-color: ${p => p.light ? p.theme.colors.text : p.lemon ? "none" : p.theme.colors.accent};
    color: ${p => p.light ? p.theme.colors.background : p.lemon ? p.theme.colors.background : p.theme.colors.background};
    // border: 1px solid ${p => p.light ? p.theme.colors.text : p.lemon ? p.theme.colors.background : p.theme.colors.background};
  }
`

const StyledText = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StandardButton = (props) => {
    return (
        <Comp onClick={props.onClick} ref={props.innerRef} {...props} light={props.light} lemon={props.lemon}>
            <StyledText s={props.s ? props.s : "buttonStandard"} t="uppercase">
                {props.children}
            </StyledText>
        </Comp>
    );
};

export default StandardButton;
