import React from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import Spacer from "../layout/Spacer";
import {TriangleArrow} from "../icons/TriangleArrow";
import Link from "../localization/Link";

const Comp = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;

  :hover {
    .title {
      background-color: ${({theme}) => theme.colors.accent};
      color: ${({theme}) => theme.colors.background};
    }
  }
`

const DateTime = styled(Text)`
  display: flex;
`

const Title = styled(Text)`
  display: flex;
  width: 100%;
    //border: 1px solid ${({theme}) => theme.colors.accent};
  background-color: ${({theme}) => theme.colors.text};
  color: ${({theme}) => theme.colors.background};
  padding: 9px 20px 9px;
  align-items: center;
  justify-content: space-between;
  transition: all 0.25s;
`

const EventText = styled(Text)`
  display: flex;
  width: fit-content;
    // border-left: 1px solid ${({theme}) => theme.colors.accent};
    // border-right: 1px solid ${({theme}) => theme.colors.accent};
    // border-bottom: 1px solid ${({theme}) => theme.colors.accent};
  padding: 9px 0 9px;
  align-items: center;
  justify-content: space-between;
  transition: all 0.25s;
`

const Place = styled(Text)`
  display: flex;
  background-color: ${({theme}) => theme.colors.text};
  color: ${({theme}) => theme.colors.background};
  width: fit-content;
  padding: 5px 20px 5px;
`

const ArrowContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 0.625rem;
`

const Arrow = styled(TriangleArrow)`
  transform: rotateZ(-90deg);
`

const Event = (props) => {
    return (
        <Comp to={props.link ? props.link : null}>
            <DateTime
                className="dateTime"
                s="standard"
                t="uppercase"
            >
                {props.dateTime}
            </DateTime>
            <Spacer s="smaller"/>

            <Title
                className="title"
                s="large"
            >
                {props.title}

                {props.link ? <ArrowContainer>
                    <Arrow/>
                </ArrowContainer> : null}
            </Title>
            {props.text ? <EventText s="small">
                {props.text}
            </EventText> : null}

            <Place
                className="place"
                s="standard"
                t="uppercase"
            >
                {props.place}
            </Place>

        </Comp>
    );
};

export default Event;
