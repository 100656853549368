import React, {createContext, useContext, useState} from "react";

export const SplashContext = createContext();

// We wrap the provider in a nice little component
// which will hold the state and provide methods to
// update the state
function SplashProvider(props) {
    const [hover, setHover] = useState(false);
    const [id, setID] = useState(null);
    const [anim, setAnim] = useState("down")

    const splashData = {hover, setHover, id, setID, anim, setAnim};

    return <SplashContext.Provider value={splashData} {...props} />;
}

// Here we create a custom hook that allows us to consume
// the context
function useSplashContext() {
    return useContext(SplashContext);
}

export {SplashProvider, useSplashContext};
