import React from "react";
import styled, {useTheme} from "styled-components";
import Icon from "../Icon";

const Svg = styled(Icon)`
  width: 100%;
  height: auto;
  color: inherit;
`;

const Phrase = styled.text`
  font-size: 2.5rem;
  font-family: ${({theme}) => theme.fonts.GtAmericaExpanded.family};
`

export const Building = React.forwardRef((props, ref) => {
    const {buildingRef, phraseRef} = ref.current;
    const theme = useTheme();

    return (<Svg viewBox="0 0 800 235">
            {/*<path ref={ref} d="M555 58V0H246V101H0V235H800V58H555Z" fill="currentColor"/>*/}

            <path
                ref={buildingRef}
                d={props.path}
                fill="currentColor"
                fillRule="nonzero"
                // fill="none"
                // stroke="currentColor"
                // strokeWidth="2"
            />
            <Phrase
                id="textid"
                fill={theme.colors.background}
                ref={phraseRef}
                x="50%"
                // y="50%"
                dominantBaseline="middle"
                textAnchor="middle"
            >
                {props.text.toUpperCase()}
            </Phrase>

            {/*<g>*/}
            {/*    <path*/}
            {/*        d="M95.72 29.36L110.68 0.96H105.96L94.4 23C93.88 24.08 93.6 24.76 93.4 25.52H93.28C93.08 24.76 92.8 24.08 92.28 23L80.72 0.96H76L90.96 29.36H95.72Z"*/}
            {/*        fill="white"/>*/}
            {/*    <path*/}
            {/*        d="M129.738 30.32C140.858 30.32 148.418 24.2 148.418 15.16C148.418 6.12 140.858 0 129.738 0C118.618 0 111.058 6.12 111.058 15.16C111.058 24.2 118.618 30.32 129.738 30.32ZM129.738 26.84C121.258 26.84 115.498 22.08 115.498 15.16C115.498 8.24 121.258 3.48 129.738 3.48C138.218 3.48 144.018 8.24 144.018 15.16C144.018 22.08 138.218 26.84 129.738 26.84Z"*/}
            {/*        fill="white"/>*/}
            {/*    <path d="M158.503 29.36V0.96H154.302V29.36H158.503Z" fill="white"/>*/}
            {/*    <path*/}
            {/*        d="M197.579 29.36L189.139 17.4C194.619 16.32 196.419 12.48 196.419 9.36C196.419 5.52 194.179 0.96 186.259 0.96H167.779V29.36H171.979V17.92H184.419L192.499 29.36H197.579ZM185.539 4.4C190.579 4.4 192.059 6.96 192.059 9.32C192.059 11.8 190.539 14.52 185.499 14.52H171.979V4.4H185.539Z"*/}
            {/*        fill="white"/>*/}
            {/*    <path d="M216.82 0.96V4.48H230.26V29.36H234.5V4.48H247.94V0.96H216.82Z" fill="white"/>*/}
            {/*    <path*/}
            {/*        d="M266.339 30.32C277.459 30.32 285.019 24.2 285.019 15.16C285.019 6.12 277.459 0 266.339 0C255.219 0 247.659 6.12 247.659 15.16C247.659 24.2 255.219 30.32 266.339 30.32ZM266.339 26.84C257.859 26.84 252.099 22.08 252.099 15.16C252.099 8.24 257.859 3.48 266.339 3.48C274.819 3.48 280.619 8.24 280.619 15.16C280.619 22.08 274.819 26.84 266.339 26.84Z"*/}
            {/*        fill="white"/>*/}
            {/*    <path*/}
            {/*        d="M290.291 16.28C290.291 24.32 296.011 30.32 306.291 30.32C316.491 30.32 322.251 24.36 322.251 16.28V0.96H318.011V16.16C318.011 21.96 313.731 26.76 306.291 26.76C298.851 26.76 294.531 21.96 294.531 16.16V0.96H290.291V16.28Z"*/}
            {/*        fill="white"/>*/}
            {/*    <path*/}
            {/*        d="M344.678 30.32C354.238 30.32 359.078 27.2 359.078 21.44C359.078 16.56 355.958 14.28 348.758 13.52L339.998 12.64C334.838 12.12 333.078 11.04 333.078 8.2C333.078 5.08 336.478 3.4 342.718 3.4C349.558 3.4 353.118 5.64 354.758 9.8L358.918 8.6C356.758 2.92 351.678 0 342.838 0C333.798 0 328.718 3.08 328.718 8.44C328.718 13.12 331.758 15.6 338.278 16.2L347.438 17.04C352.598 17.52 354.718 18.88 354.718 21.84C354.718 25.32 351.558 26.88 344.718 26.88C337.318 26.88 333.038 24.44 331.398 19.64L327.118 20.56C329.158 27.16 334.758 30.32 344.678 30.32Z"*/}
            {/*        fill="white"/>*/}
            {/*    <path d="M408.737 29.36V25.76H387.057V0.96H382.857V29.36H408.737Z" fill="white"/>*/}
            {/*    <path*/}
            {/*        d="M418.893 13.12V4.4H441.613V0.96H414.693V29.36H441.893V25.92H418.893V16.52H440.933V13.12H418.893Z"*/}
            {/*        fill="white"/>*/}
            {/*    <path*/}
            {/*        d="M463.428 30.32C472.988 30.32 477.828 27.2 477.828 21.44C477.828 16.56 474.708 14.28 467.508 13.52L458.748 12.64C453.588 12.12 451.828 11.04 451.828 8.2C451.828 5.08 455.228 3.4 461.468 3.4C468.308 3.4 471.868 5.64 473.508 9.8L477.668 8.6C475.508 2.92 470.428 0 461.588 0C452.548 0 447.468 3.08 447.468 8.44C447.468 13.12 450.508 15.6 457.028 16.2L466.188 17.04C471.348 17.52 473.468 18.88 473.468 21.84C473.468 25.32 470.308 26.88 463.468 26.88C456.068 26.88 451.788 24.44 450.148 19.64L445.868 20.56C447.908 27.16 453.508 30.32 463.428 30.32Z"*/}
            {/*        fill="white"/>*/}
            {/*    <path*/}
            {/*        d="M501.607 0.96V29.36H505.807V19.04H518.967C525.887 19.04 530.407 15.44 530.407 9.88C530.407 4.4 526.367 0.96 519.887 0.96H501.607ZM505.807 15.64V4.4H519.127C523.447 4.4 526.047 6.44 526.047 9.88C526.047 13.44 523.367 15.64 519.087 15.64H505.807Z"*/}
            {/*        fill="white"/>*/}
            {/*    <path*/}
            {/*        d="M566.017 29.36L557.577 17.4C563.057 16.32 564.857 12.48 564.857 9.36C564.857 5.52 562.617 0.96 554.697 0.96H536.217V29.36H540.417V17.92H552.857L560.937 29.36H566.017ZM553.977 4.4C559.017 4.4 560.497 6.96 560.497 9.32C560.497 11.8 558.977 14.52 553.937 14.52H540.417V4.4H553.977Z"*/}
            {/*        fill="white"/>*/}
            {/*    <path*/}
            {/*        d="M587.082 30.32C598.202 30.32 605.762 24.2 605.762 15.16C605.762 6.12 598.202 0 587.082 0C575.962 0 568.402 6.12 568.402 15.16C568.402 24.2 575.962 30.32 587.082 30.32ZM587.082 26.84C578.602 26.84 572.842 22.08 572.842 15.16C572.842 8.24 578.602 3.48 587.082 3.48C595.562 3.48 601.362 8.24 601.362 15.16C601.362 22.08 595.562 26.84 587.082 26.84Z"*/}
            {/*        fill="white"/>*/}
            {/*    <path*/}
            {/*        d="M621.548 0.96H617.308V18.44C617.308 24.32 614.828 26.12 610.348 26.12C608.068 26.12 606.708 25.92 605.188 25.68V29.52C606.788 29.88 608.188 29.96 610.508 29.96C617.548 29.96 621.548 26.76 621.548 18.72V0.96Z"*/}
            {/*        fill="white"/>*/}
            {/*    <path*/}
            {/*        d="M633.893 13.12V4.4H656.613V0.96H629.693V29.36H656.893V25.92H633.893V16.52H655.933V13.12H633.893Z"*/}
            {/*        fill="white"/>*/}
            {/*    <path d="M660.922 0.96V4.48H674.362V29.36H678.602V4.48H692.042V0.96H660.922Z" fill="white"/>*/}
            {/*    <path*/}
            {/*        d="M709.6 30.32C719.16 30.32 724 27.2 724 21.44C724 16.56 720.88 14.28 713.68 13.52L704.92 12.64C699.76 12.12 698 11.04 698 8.2C698 5.08 701.4 3.4 707.64 3.4C714.48 3.4 718.04 5.64 719.68 9.8L723.84 8.6C721.68 2.92 716.6 0 707.76 0C698.72 0 693.64 3.08 693.64 8.44C693.64 13.12 696.68 15.6 703.2 16.2L712.36 17.04C717.52 17.52 719.64 18.88 719.64 21.84C719.64 25.32 716.48 26.88 709.64 26.88C702.24 26.88 697.96 24.44 696.32 19.64L692.04 20.56C694.08 27.16 699.68 30.32 709.6 30.32Z"*/}
            {/*        fill="white"/>*/}
            {/*</g>*/}

            {/*<path*/}
            {/*    // d="M 57 57 h -9 v -18 h -30 v 18 h -9 v -42 h 9 v 17 h 30 v -17 h 9 v 42 Z"*/}
            {/*    d={props.path}*/}
            {/*    ref={ref}*/}
            {/*    fill="currentColor"*/}
            {/*/>*/}

            {/*<path*/}
            {/*    d="M 50 50 v 7 h -41 v -42 h 41 v 7 h -32 v 11 h 31 v 6 h -31 v 11 Z"*/}
            {/*    fill="currentColor"*/}
            {/*/>*/}
        </Svg>
    )
});
