import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
  width: auto;
  height: 12px;
  color: inherit;
  //margin-top: 6px;
`;

export const Ex = (props) => (
    <Svg viewBox="0 0 12 12" focus={props.focus}>
        <line x1="0.748611" y1="1.05028" x2="10.6481" y2="10.9498" stroke="currentColor" strokeWidth="1"/>
        <line x1="0.74846" y1="10.9498" x2="10.648" y2="1.05026" stroke="currentColor" strokeWidth="1"/>
    </Svg>
);
