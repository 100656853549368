import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
  width: 100px;
  min-width: 100px;
  height: auto;
  color: inherit;
`;

export const Eye = (props) => (
    <Svg viewBox="0 0 30 20">
        <path d="M2 10C2 10 6.72727 2 15 2C23.2727 2 28 10 28 10C28 10 23.2727 18 15 18C6.72727 18 2 10 2 10Z"
              stroke="currentColor" strokeWidth="2" fill="none"/>
        <path
            d="M15 14C17.2091 14 19 12.2091 19 10C19 7.79086 17.2091 6 15 6C12.7909 6 11 7.79086 11 10C11 12.2091 12.7909 14 15 14Z"
            fill="currentColor" stroke="none"/>
    </Svg>
);
