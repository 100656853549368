import React, {forwardRef, useEffect, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
// import {GatsbyImage} from "gatsby-plugin-image";
import {truncate} from "../../../lib/utils";
import Text from "../../layout/Text";
import Spacer from "../../layout/Spacer";
import Link from "../../localization/Link";
import {useVariationContext} from "../../../contexts/VariationContext";
import Icon from "../../icons/Icon";
import {gsap} from "gsap";
import {Eye} from "../../icons/Eye";

const Comp = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  //flex-shrink: 0;
  min-width: 100%;
`

const ImgContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
    //display: ${p => p.hover ? "none" : "block"};
    //background-color: ${p => p.hover ? p.theme.colors.accent : "transparent"};
  //transition: all 0.25s;
  //padding-top: 100%;
    //background-color: ${({theme}) => theme.colors.accent};
  position: relative;
`

// const Image = styled(GatsbyImage)`
//   width: 100%;
//   height: 100%;
//   max-height: 100%;
//   object-fit: cover;
//   //display: none;
//   //user-select: none;
//     //  opacity: ${p => p.hover ? 0 : 1};
//   //transition: all 5s;
// `

const TextContainer = styled(Link)`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 4rem;
  //left: 50%;
  //transform: translateX(-50%);
  //width: 50%;
  width: calc(100% - 3rem);
  background-color: ${({theme}) => theme.colors.background};
  padding: 1rem;
  //margin: 0 1.5rem;
`

const Caption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

const TextItem = styled(Text)`
  text-align: inherit;
  text-overflow: ellipsis;
`

const EyeContainer = styled.div`
  max-width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: auto;
    min-width: auto;
  }
`

const Svg = styled(Icon)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const SplashMobileProject = forwardRef((props, ref) => {
    const {project, isSliding} = props;
    const variation = useVariationContext()
    const shapeRef = useRef()

    const pathArray = useMemo(() => {
        return [
            "m 735.13 925.29 l 359 114.71 l -104.35 -534.85 l 242 383.7 s 97.49 -300.27 97.49 -300.27 l -335 -118.18 l 323.88 -74.13 l -89.1 -220.27 l -251.05 268.41 l 194.38 -404.41 l -310.38 48.13 l 88.17 356.28 l -208.29 -359.88 l -151.15 299.6 l 345.93 91.77 l -316.24 64.1 l 89.74 210.53 l 229.69 -245.38 z",
            "m 1078 492.18 q -2.9 -9 -6.34 -17.49 l 207.53 -348.69 l -249 287.88 a 116.93 116.93 0 0 0 -14.19 -10.64 l 61.52 -169.48 l -107.14 153 a 120 120 0 0 0 -20.28 -0.8 l 70.83 -346 l -153.4 374.04 q -6.28 3.88 -12.53 8.26 v -347.91 l -70.24 414.4 q -7.18 8.76 -14.14 18.16 l -50.56 -217.91 l -8.65 320.43 q -4.42 9.95 -8.56 20.19 l -86.85 -259.05 l 53.46 373.43 q -1.69 9.26 -3 18.38 l -70.12 -104.2 l 67.45 220.75 c 9.2 76.62 41.86 134.65 94.77 154.17 c 65.1 24 144.73 -16.21 210.3 -96.33 l 138.07 -54.71 l -105.41 9.62 q 10.58 -16.47 20.31 -34.38 l 264.8 -226.61 l -216.39 108.61 q 3.42 -11.71 6.3 -23.34 l 247.46 -236.17 l -231.73 118 q 0.1 -11.34 -0.36 -22.32 l 187.31 -225.59 z",
            "m 995.23 364 c 0 16.65 0.9 33.36 0 50.74 c -1 18.27 -14.43 39.93 -29.28 39.93 c -12.73 0 -18.85 -8.89 -18.85 -29.05 c 0 -23.74 8 -59.29 8 -96.15 c 0 -72.36 -62.46 -190.33 -121.18 -190.33 c -40.63 0 -74.9 30.83 -74.9 87.77 c 0 135 172 158.14 172 244.36 c 0 8.66 -4.69 14 -15.82 14 c -78.8 0 -69.22 -122 -212 -122 c -44.34 0 -72.4 27.81 -72.4 61 c 0 44.31 58 92.11 143.48 92.11 c 40.18 0 102.16 -15.37 116.13 -15.37 c 24.09 0 31.73 6.15 31.73 20.07 c 0 58.06 -329.93 36.69 -329.93 223.35 c 0 43.88 34 86.18 80.32 86.18 c 235.64 0 209.29 -268 270.64 -268 c 13.67 0 19.65 8.32 19.65 31.16 c -0.02 84.23 -21.82 130.84 -21.82 189.7 c 0 160 73.17 256.53 171.15 256.53 c 66.59 0 110.48 -47.07 110.48 -116.33 c 0.04 -224.94 -231.63 -287.37 -231.63 -360.47 c 0 -16.83 8.67 -22.46 21.36 -22.46 c 64.72 0 97.79 121.85 222.41 121.85 c 39.4 0 60.79 -27.44 60.79 -75.75 c 0 -53.75 -57.78 -85.89 -125.15 -85.89 c -48.52 0 -69.53 22.71 -118.28 22.71 c -30.13 0 -45.82 -16.22 -45.82 -41.87 c 0 -101.21 321.47 -58.75 321.47 -376.25 c 0 -41 -18.92 -65.54 -53.85 -65.54 c -124.2 -0 -278.7 127.66 -278.7 324 z"
        ]
    }, [])

    const pathArray2 = useMemo(() => {
        return [
            "m 735.13 925.29 l 359 114.71 l 26.87 -164 l 110.78 12.85 s 97.49 -300.27 97.49 -300.27 l -71.27 -122.58 l 60.15 -69.73 l -89.1 -220.27 l -81.05 -21 l 24.38 -115 l -310.38 48.13 l -42 47.87 l -78.12 -51.47 l -151.15 299.6 l 75.27 83.87 l -45.58 72 l 89.74 210.53 l 54.84 44.47 z",
            "m 1078 492.18 q -2.9 -9 -6.34 -17.49 l 207.53 -348.69 l -249 287.88 a 116.93 116.93 0 0 0 -14.19 -10.64 l 61.52 -169.48 l -107.14 153 a 120 120 0 0 0 -20.28 -0.8 l 70.83 -346 l -153.4 374.04 q -6.28 3.88 -12.53 8.26 v -347.91 l -70.24 414.4 q -7.18 8.76 -14.14 18.16 l -50.56 -217.91 l -8.65 320.43 q -4.42 9.95 -8.56 20.19 l -86.85 -259.05 l 53.46 373.43 q -1.69 9.26 -3 18.38 l -70.12 -104.2 l 67.45 220.75 c 9.2 76.62 41.86 134.65 94.77 154.17 c 65.1 24 144.73 -16.21 210.3 -96.33 l 138.07 -54.71 l -105.41 9.62 q 10.58 -16.47 20.31 -34.38 l 264.8 -226.61 l -216.39 108.61 q 3.42 -11.71 6.3 -23.34 l 247.46 -236.17 l -231.73 118 q 0.1 -11.34 -0.36 -22.32 l 187.31 -225.59 z",
            "m 995.23 364 c 0 16.65 0.9 33.36 0 50.74 c -1 18.27 -14.43 39.93 -29.28 39.93 c -12.73 0 -18.85 -8.89 -18.85 -29.05 c 0 -23.74 8 -59.29 8 -96.15 c 0 -72.36 -62.46 -190.33 -121.18 -190.33 c -40.63 0 -74.9 30.83 -74.9 87.77 c 0 135 172 158.14 172 244.36 c 0 8.66 -4.69 14 -15.82 14 c -78.8 0 -69.22 -122 -212 -122 c -44.34 0 -72.4 27.81 -72.4 61 c 0 44.31 58 92.11 143.48 92.11 c 40.18 0 102.16 -15.37 116.13 -15.37 c 24.09 0 31.73 6.15 31.73 20.07 c 0 58.06 -329.93 36.69 -329.93 223.35 c 0 43.88 34 86.18 80.32 86.18 c 235.64 0 209.29 -268 270.64 -268 c 13.67 0 19.65 8.32 19.65 31.16 c -0.02 84.23 -21.82 130.84 -21.82 189.7 c 0 160 73.17 256.53 171.15 256.53 c 66.59 0 110.48 -47.07 110.48 -116.33 c 0.04 -224.94 -231.63 -287.37 -231.63 -360.47 c 0 -16.83 8.67 -22.46 21.36 -22.46 c 64.72 0 97.79 121.85 222.41 121.85 c 39.4 0 60.79 -27.44 60.79 -75.75 c 0 -53.75 -57.78 -85.89 -125.15 -85.89 c -48.52 0 -69.53 22.71 -118.28 22.71 c -30.13 0 -45.82 -16.22 -45.82 -41.87 c 0 -101.21 321.47 -58.75 321.47 -376.25 c 0 -41 -18.92 -65.54 -53.85 -65.54 c -124.2 -0 -278.7 127.66 -278.7 324 z"
        ]
    }, [])

    const authors = project ? project.authors.map((item, index) => {
        return (
            <React.Fragment key={index}>
                {item.firstName} {item.lastName}
            </React.Fragment>
        )
    }) : null

    const field = project ? project.authors[0].diploma.shortName ? project.authors[0].diploma.shortName : project.authors[0].diploma.name : null

    // ANIM
    // useEffect(() => {
    //     gsap.set(shapeRef.current, {
    //         scale: 50,
    //         rotateZ: "0deg",
    //         transformOrigin: "center center"
    //     });
    // }, [])

    const [tl] = useState(gsap.timeline({
        // paused: true
    }))

    const transformOrigins = useMemo(() => {
        return [
            "50.7376% 43.4778% ", // WINDMILL
            "38.535% 67% ", // METEOR
            "50.2979% 47% " // FLOWER
        ]
    }, [])

    const animArray = useMemo(() => {
        return [
            // SHAPE 1 WINDMILL
            {
                appear:
                    [
                        {
                            scale: 1,
                            // rotateZ: "210deg",
                            duration: 0.25,
                            ease: 'power3.out',
                            attr: {
                                d: pathArray[variation.live]
                            },
                        },
                    ],
                hide: {
                    scale: 3,
                    // rotateZ: "0deg",
                    duration: 0.25,
                    ease: 'power3.out',
                    attr: {
                        d: pathArray2[variation.live]
                    },
                }
            },
            // SHAPE 2 METEOR
            {
                appear:
                    [
                        {
                            scale: 1,
                            // rotateZ: "90deg",
                            duration: 0.25,
                            ease: 'power3.out',
                        },
                    ],
                hide: {
                    scale: 10,
                    // rotateZ: "0deg",
                    duration: 0.25,
                    ease: 'power3.in',
                },
            },
            // SHAPE 3 FLOWER
            {
                appear:
                    [
                        {
                            scale: 1,
                            // rotateZ: "90deg",
                            duration: 0.25,
                            ease: 'power3.out',
                        },
                    ],
                hide: {
                    scale: 18,
                    // rotateZ: "0deg",
                    duration: 0.25,
                    ease: 'power3.in',
                },
            }
        ]
    }, [variation.live, pathArray, pathArray2])

    // appear anim
    useEffect(() => {
        tl.to(shapeRef.current,
            {
                scale: 0,
                duration: 0,
                transformOrigin: transformOrigins[variation.live]
            })


        tl.to(shapeRef.current, {
            ...animArray[variation.live].hide,
            transformOrigin: transformOrigins[variation.live]
        });
    }, [project, animArray, tl, variation.live, transformOrigins])

    useEffect(() => {
        const appearAnim = () => {
            for (let i = 0; i < animArray[variation.live].appear.length; i++) {
                tl.to(shapeRef.current, {
                        ...animArray[variation.live].appear[i],
                        transformOrigin: transformOrigins[variation.live]
                    }
                )
            }
        }

        const hideAnim = () => {
            tl.clear()
            tl.to(shapeRef.current, {
                ...animArray[variation.live].hide,
                transformOrigin: transformOrigins[variation.live]
            });
        }

        if (isSliding) {
            appearAnim()
        } else {
            hideAnim()
        }

    }, [tl, isSliding, animArray, variation.live, transformOrigins])

    return (
        <Comp ref={ref}>

            <ImgContainer>
                {/*{!isSliding ?*/}
                {/*    <Image*/}
                {/*        alt={"data.coverImage.image.text"}*/}
                {/*        image={project ? project.coverImage.image.asset.gatsbyImageData : null} className="image"*/}
                {/*    /> : null}*/}
                <Svg
                    viewBox="0 0 1920 1080"
                    preserveAspectRatio="xMidYMid slice"
                >
                    <mask id="maskMobile">
                        <path
                            ref={shapeRef}
                            // base origin: 865.52 812.53
                            // d="m 865.52 812.53 q -5.19 -16.24 -11.37 -31.44 l 372.95 -626.6 l -447.53 517.33 a 211.67 211.67 0 0 0 -25.5 -19.12 l 110.55 -304.55 l -192.55 275 a 215.34 215.34 0 0 0 -36.52 -1.46 l 127.3 -621.69 l -275.55 672.05 q -11.26 7 -22.52 14.84 v -625.21 l -126.16 744.68 q -12.93 15.77 -25.41 32.64 l -90.85 -391.6 l -15.57 575.79 q -8 17.86 -15.4 36.27 l -156.03 -465.46 l 96 671.12 q -3 16.61 -5.44 33 l -125.92 -187.29 l 121.17 396.73 c 16.6 137.77 75.23 242 170.31 277 c 117 43.15 260.08 -29.12 377.92 -173.1 l 248.1 -98.32 l -189.43 17.28 q 19 -29.58 36.52 -61.78 l 475.87 -407.31 l -388.86 195.28 q 6.15 -21.06 11.31 -41.95 l 444.66 -424.4 l -416.48 212 q 0.23 -20.38 -0.63 -40.12 l 336.64 -405.39 z"
                            // d="m 1078 492.18 q -2.9 -9 -6.34 -17.49 l 207.53 -348.69 l -249 287.88 a 116.93 116.93 0 0 0 -14.19 -10.64 l 61.52 -169.48 l -107.14 153 a 120 120 0 0 0 -20.28 -0.8 l 70.83 -346 l -153.4 374.04 q -6.28 3.88 -12.53 8.26 v -347.91 l -70.24 414.4 q -7.18 8.76 -14.14 18.16 l -50.56 -217.91 l -8.65 320.43 q -4.42 9.95 -8.56 20.19 l -86.85 -259.05 l 53.46 373.43 q -1.69 9.26 -3 18.38 l -70.12 -104.2 l 67.45 220.75 c 9.2 76.62 41.86 134.65 94.77 154.17 c 65.1 24 144.73 -16.21 210.3 -96.33 l 138.07 -54.71 l -105.41 9.62 q 10.58 -16.47 20.31 -34.38 l 264.8 -226.61 l -216.39 108.61 q 3.42 -11.71 6.3 -23.34 l 247.46 -236.17 l -231.73 118 q 0.1 -11.34 -0.36 -22.32 l 187.31 -225.59 z"
                            d={pathArray[variation.live]}
                            fill="white"/>
                    </mask>
                    {!project.coverImage ? <rect x={0} y={0} width={"100%"} height={"100%"} fill="currentColor"
                                                 mask="url(#maskMobile)"/> : null}
                    <image xmlnsXlink="http://www.w3.org/1999/xlink"
                           opacity="1"
                        // xlinkHref="https://cdn.sanity.io/images/9iomroay/production/7a43fc8ae6a54529de45ac2ddd725443298162d3-2560x1828.jpg?w=1920&h=1371&auto=format"
                           xlinkHref={project && project.coverImage ? project.coverImage.image.asset.gatsbyImageData.images.fallback.src : null}
                           x="0"
                           y="0"
                           width="100%"
                           height="100%"
                           preserveAspectRatio="xMidYMid slice"
                           mask="url(#maskMobile)"
                    />
                </Svg>
            </ImgContainer>

            {/*<Link to={project.slug ? "/projects/" + project.slug.current : null}>*/}
            <TextContainer
                even={props.index % 2 === 0 ? 1 : 0}
                to={project.slug ? "/projects/" + project.slug.current : null}
            >
                <Caption>
                    <TextItem s="cardsField">
                        {field}
                    </TextItem>
                    <Spacer s="small"/>
                    <TextItem s="cardsName">
                        {authors}
                    </TextItem>
                    <Spacer s="small"/>
                    <TextItem s="cardsTitle">
                        {project ? truncate(project.title, 50, true) : null}
                    </TextItem>
                </Caption>

                <EyeContainer>
                    <Eye/>
                </EyeContainer>
            </TextContainer>
            {/*</Link>*/}
        </Comp>
    );
});

export default SplashMobileProject;
