import React, {useState} from 'react';
import styled from "styled-components";
import Burger from "./Burger";
import GlobalWidth from "./GlobalWidth";
import HeaderLogo from "./HeaderLogo";
import MainMenu from "./MainMenu";
import Text from "./Text";
import Link from "../localization/Link";
import {useLanguageContext} from "../../contexts/LanguageContext";
import Conditional from "./Conditional";
import SelectYear from "./SelectYear";
import Spacer from "./Spacer";

const Comp = styled(GlobalWidth)`
  display: flex;
  position: fixed;
  z-index: 200;
  justify-content: space-between;
  //background-color: palegoldenrod;
  //background-image: linear-gradient(rgba(248, 249, 250, 1) 0%, rgba(248, 249, 250, 0.4) 70%, rgba(248, 249, 250, 0) 100%);
  height: ${({theme}) => theme.sizes.headerHeight};
  align-items: center;

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    // prev m
    height: ${({theme}) => theme.sizes.headerHeightMobile};
  }
`

const Title = styled(Text)`
  text-align: center;
  transition: color 0.5s;
  justify-self: center;

  :hover {
    color: ${({theme}) => theme.colors.accent};
  }
`

const Left = styled.div`
  display: flex;
  align-items: center;
`

// const Background = styled.div`
//   width: 150%;
//   height: 100%;
//   background-color: ${({theme}) => theme.colors.background};
//   position: absolute;
//   top: -2px;
//   left: 0;
//   transform: translateX(-25%);
//   z-index: -10;
//   filter: blur(10px);
// `

const Header = (props) => {
    const {headerData} = props
    const [mainMenu, setMainMenu] = useState(false)
    const language = useLanguageContext();

    function toggle() {
        setMainMenu(!mainMenu)
    }

    return (
        <Comp>
            <Left>
                <Burger
                    toggled={mainMenu}
                    toggle={toggle}
                />
                <Spacer s="small"/>

                <SelectYear/>
            </Left>

            <Conditional desktop>
                <Link to="/">
                    <Title s="cardsField">
                        {headerData[0].text[language.lang].split(', ').map((item, index) => {
                            return <React.Fragment key={index}>{item} <br/></React.Fragment>
                        })}
                    </Title>
                </Link>
            </Conditional>

            <MainMenu
                lang={props.lang}
                data={props.mainMenuData}
                open={mainMenu}
                toggle={setMainMenu}
            />

            {props.noLogo ? null : <HeaderLogo/>}

            {/*<Background/>*/}
        </Comp>
    );
};

export default Header;
