import React from 'react';
import styled from "styled-components";
import AwardProject from "./AwardProject";
import GlobalWidth from "../layout/GlobalWidth";
import Spacer from "../layout/Spacer";
import BigTitle from "../UI/BigTitle";

const Comp = styled(GlobalWidth)`
  display: flex;
  flex-direction: column;
  //align-items: center;
`

const AwardsList = (props) => {
    const {projects} = props;
    const awardedProjects = projects.filter(i => i.awards.length > 0)
    const display = awardedProjects.map((item, index) => {
        return (
            <React.Fragment key={index}>
                <AwardProject data={item}/>
                <Spacer s="large"/>
            </React.Fragment>
        )
    })

    return (
        <Comp>
            <div style={{alignSelf: "center"}}>
                <BigTitle>Awards</BigTitle>
            </div>
            <Spacer s="large"/>

            {display}
        </Comp>
    );
};

export default AwardsList;
