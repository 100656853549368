import React from 'react';
import Diploma from "./Diploma";
import Spacer from "../layout/Spacer";

const Diplomas = (props) => {
    const bachelor = props.bachelorContent.map(item => {
        const link = item.identification.id === "arts" ? "5edbd991-7325-488e-991a-1e8c8cf86d30" :
            item.identification.id === "cinema" ? "b20d1e27-bdb5-428d-9f6a-1eee3ada87aa" :
                item.identification.id === "visualcommunication" ? "ad5cc3f5-e1ec-4271-83c2-16281c1fc655" :
                    item.identification.id === "interiorarchitecture" ? "946275c1-0918-4d35-981d-9c672371bd75" :
                        item.identification.id === "fashiondesign" ? "5d658c59-3e92-4aca-907a-2997f67fbc7b" :
                            item.identification.id === "jewelry" ? "030a7009-d9d6-477a-96fc-b57e971c7943" :
                                ""

        return {
            title: item.text,
            image: item.image ? item.image.asset.gatsbyImageData : null,
            credit: item.credit,
            link: "/catalogue#diplomas=" + link
        }
    })

    // console.log(props.masterContent)

    const master = props.masterContent.map(item => {
        const link = item.identification.id === "ccc" ? "43fc388c-6187-45ad-9dd8-ef410533c855" :
            item.identification.id === "trans" ? "9056a4e8-e7c4-4fe2-bb1a-076f97705e0d" :
                item.identification.id === "work.master" ? "48789424-c294-4c81-8238-fbfea5a59872" :
                    item.identification.id === "cinema" ? "eafa09b9-1ccb-461c-95cf-3112e957ee6d" :
                        item.identification.id === "mediadesign" ? "2be50e0b-9cd2-450c-a611-763129be3328" :
                            item.identification.id === "spaceandcommunication" ? "49b92a46-9647-46fc-80eb-98ffb6691383" :
                                item.identification.id === "interiorarchitecture" ? "b91607ba-edcf-4d7c-aaab-382461a3c4d0" :
                                    item.identification.id === "fashiondesign" ? "2c27e4d5-9c97-4cf6-b88e-423bcc9d1910" :
                                        ""

        return {
            title: item.text,
            image: item.image ? item.image.asset.gatsbyImageData : null,
            credit: item.credit,
            link: "/catalogue#diplomas=" + link
        }
    })

    // console.log(props.bachelor)
    return (
        <>
            <Diploma
                title={props.bachelor.find(item => item.identification.id === "title").content}
                cards={bachelor}
            />
            <Spacer s="larger"/>

            <Diploma
                title={props.master.find(item => item.identification.id === "title").content}
                cards={master}
            />
        </>
    );
};

export default Diplomas;
