import React from 'react';
import styled from "styled-components";
import GlobalStyle from "../../style/GlobalStyle";
import "../../fonts/fonts.css";// fonts
import Header from "./Header";
import Spacer from "./Spacer";
import {graphql, useStaticQuery} from "gatsby";
import Seo from "../seo";
import Footer from "./footer/Footer";

const Page = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //position: relative;
`;

const Layout = ({children, ...props}) => {
    const generalData = useStaticQuery(graphql`
        {
            sanityPage(title: {fr: {eq: "General"}}) { # General page
                content {
                    ... on SanityContentString {
                        content {
                            _type
                            en
                            fr
                        }
                        identification {
                            id
                            section
                        }
                    }
                    ... on SanityContentText {
                        content {
                            _type
                            en
                            fr
                        }
                        identification {
                            id
                            section
                        }
                    }
                    ... on SanityContentUrl {
                        _type
                        identification {
                            id
                            section
                        }
                        link {
                            _type
                            en
                            fr
                        }
                        text {
                            _type
                            en
                            fr
                        }
                    }
                }
            }
        }
    `).sanityPage.content

    const footerData = generalData.filter(item => item.identification.section === "footer")
    const mainMenuData = generalData.filter(item => item.identification.section === "main menu")
    const headerData = generalData.filter(item => item.identification.section === "header")

    return (
        <Page>
            <Seo title={props.title}/>
            <GlobalStyle/>
            {/*<TopGradient/>*/}

            <Header
                lang={props.lang}
                mainMenuData={mainMenuData}
                noLogo={props.noLogo}
                headerData={headerData}
            />

            {children}

            <Spacer s="large"/>
            <Footer data={footerData}/>
        </Page>
    );
};

export default Layout;
