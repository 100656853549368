import React from 'react';
import styled from "styled-components";
import {useProjectContext} from "../../contexts/ProjectsContext";
import {Ex} from "../icons/Ex";
import Text from "../layout/Text";
import Spacer from "../layout/Spacer";
import Conditional from "../layout/Conditional";

const Comp = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover .line {
    //display: block;
    width: 100%;
  }

  @media (max-width: ${({theme}) => theme.breakPoints.s}) { // prev m
    margin-bottom: 0.5rem;
    margin-right: 1rem;
  }
`

const LineThrough = styled.div`
  //display: none;
  position: absolute;
  height: 1px;
  width: 0;
  border-bottom: 1px solid ${({theme}) => theme.colors.accent};
  top: 55%;
  transition: all 0.25s;
`

const FilterTag = (props) => {
    const projects = useProjectContext()

    function handleClick() {
        projects.removeFilters(props.filter, props.id)
    }

    return (
        <>
            <Comp onClick={handleClick}>
                <Ex/>
                <Spacer s="smaller"/>

                <Text s={props.menu ? "small" : "smaller"}>
                    {props.filter.label}
                </Text>

                <LineThrough className="line"/>
            </Comp>

            <Conditional desktop>
                <Spacer s="medium"/>
            </Conditional>
        </>
    );
};

export default FilterTag;
