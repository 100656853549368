import React, {forwardRef} from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
  width: auto;
  height: 1rem;
  color: inherit;
`;

export const ReloadArrow = forwardRef((props, ref) => (
    <Svg viewBox="0 0 14 14" ref={ref}>
        <path
            d="M13.9685 5.94595H8.78808L10.9348 3.79925L10.6088 3.42032C10.0258 2.81503 9.3018 2.36365 8.50166 2.10654C7.70152 1.84943 6.85014 1.79461 6.02363 1.94696C5.19712 2.09932 4.42125 2.45412 3.76538 2.97962C3.10951 3.50513 2.59408 4.18498 2.26517 4.95838C1.93627 5.73178 1.80414 6.57463 1.8806 7.41158C1.95706 8.24853 2.23972 9.05349 2.70332 9.75449C3.16692 10.4555 3.79701 11.0307 4.53724 11.4287C5.27748 11.8266 6.1048 12.035 6.94523 12.035C7.99703 12.034 9.02276 11.7076 9.8817 11.1006C10.7406 10.4935 11.3907 9.63559 11.7427 8.64444L12.0553 7.76376L13.8114 8.38484L13.5029 9.26552C13.0784 10.4668 12.3318 11.5283 11.3448 12.334C10.3578 13.1397 9.1683 13.6586 7.90632 13.8339C6.64434 14.0092 5.35844 13.8342 4.18918 13.3281C3.01991 12.822 2.01228 12.0042 1.2764 10.9641C0.540524 9.92398 0.104725 8.7016 0.0166337 7.43054C-0.0714571 6.15949 0.19155 4.88868 0.776911 3.757C1.36227 2.62532 2.24746 1.67633 3.33573 1.01375C4.42399 0.35117 5.67346 0.00049348 6.94756 5.02767e-05C7.88788 -0.00354224 8.81898 0.185429 9.68349 0.555322C10.548 0.925215 11.3276 1.4682 11.9744 2.15082L12.007 2.18691L12.2578 2.47794L13.9685 0.767807V5.94595Z"
            fill="currentColor"
        />

    </Svg>
));
