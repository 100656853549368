import {useCallback, useEffect, useRef, useState} from 'react';
import {randomInt} from "../lib/utils";

function countTags(array, value) {
    let count = 0;
    array.forEach((v) => (v._id === value && count++));
    return count;
}

function getRandom(arr, n) {
    let result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        // throw new RangeError("getRandom: more elements taken than available");
        console.log("not enough projects")
    while (n--) {
        const x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}

const compare = (a, b) => {
    if (a._id < b._id) {
        return -1;
    }
    if (a._id > b._id) {
        return 1;
    }
    return 0;
}

const useSplashProjects = (projects) => {
    const onceRef = useRef(false)

    const [tag, setTag] = useState(null)
    const [tags, setTags] = useState(null)
    const [selProjects, setSelProjects] = useState(null)

    // set tag list
    useEffect(() => {
        if (!onceRef.current) {
            // get all project tags as an array
            let tempTags = projects.map(item => {
                return item.tags.flat()
            }).flat()

            // remove duplicates
            tempTags = Array.from(new Set(tempTags.map(a => a._id)))
                .map(id => {
                    const tag = tempTags.find(a => a._id === id)
                    return {
                        name: tag.name,
                        _id: tag._id,
                        projects: countTags(tempTags, tag._id)
                    }
                }).sort(compare)
                .filter(tag => {
                    return tag.projects > 5
                })
            setTags(tempTags)

            onceRef.current = true
        }
    }, [projects])


    const randomTag = useCallback(() => {
        setTag(tags ? tags[randomInt(0, tags ? tags.length : 0)] : null)
    }, [tags])

    // choose random tag
    useEffect(() => {
        if (tag === null) {
            randomTag()
        }
    }, [tags, randomTag, tag])


    // select projects with tag
    useEffect(() => {
        // console.log(tag ? tag : "")
        // console.log(tag ? projects.filter(i => i.tags.find(t => t._id === tag._id)) : null)
        // removed the condition ignoring projects without cover image
        setSelProjects(tag ? getRandom(projects.filter(i => i.tags.find(t => t._id === tag._id)), 4) : null)
    }, [projects, tag])

    return [randomTag, tag, selProjects]
};

export default useSplashProjects;
