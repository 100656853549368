import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {UpArrow} from "../icons/UpArrow";

const TheButton = styled.div`
  position: fixed;
  bottom: 2rem;
  right: ${p => p.project ? "2rem" : "50%"};
  transform: ${p => p.project ? "none" : "translateX(50%)"};
  z-index: 100;
  border: 1px solid ${p => p.theme.colors.accent};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  transition: all 0.25s;
  color: ${p => p.theme.colors.accent};
  width: 4rem;
  height: 4rem;

  :hover {
    background-color: ${p => p.theme.colors.background};
    color: ${p => p.theme.colors.accent};
  }

  @media (max-width: ${({theme}) => theme.breakPoints.s}) {
    right: 2rem;
    transform: none;
  }
`

const ScrollButton = (props) => {

    const [visible, setVisible] = useState(false)

    useEffect(() => {
        const toggleVisible = () => {
            const scrolled = document.documentElement.scrollTop;
            if (scrolled > 500) {
                setVisible(true)
            } else if (scrolled <= 300) {
                setVisible(false)
            }
        };

        if (typeof window !== "undefined") {
            window.addEventListener('scroll', toggleVisible);
        }

        return () => {
            window.removeEventListener('scroll', toggleVisible);
        }
    })

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    return (
        <>
            {visible ?
                <TheButton light={1} s='small' onClick={scrollToTop} project={props.project}>
                    {/*{props.text}*/}
                    <UpArrow/>
                </TheButton>
                : null}
        </>
    );
}

export default ScrollButton;
